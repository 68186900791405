function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { Empty } from 'antd';
import * as React from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "cec1te",
  styles: "display:flex;width:100%;flex-direction:column;align-items:center;justify-content:center"
} : {
  name: "cec1te",
  styles: "display:flex;width:100%;flex-direction:column;align-items:center;justify-content:center",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "18coy28",
  styles: "padding-bottom:1rem;font-size:3.75rem;line-height:1;color:rgba(var(--white), 0.1)"
} : {
  name: "18coy28",
  styles: "padding-bottom:1rem;font-size:3.75rem;line-height:1;color:rgba(var(--white), 0.1)",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "9x27sm",
  styles: "padding-bottom:0.5rem;font-size:14px;line-height:20px;--tw-text-opacity:1;color:rgba(var(--foreground-2), var(--tw-text-opacity));:last-child{padding-bottom:0px;}"
} : {
  name: "9x27sm",
  styles: "padding-bottom:0.5rem;font-size:14px;line-height:20px;--tw-text-opacity:1;color:rgba(var(--foreground-2), var(--tw-text-opacity));:last-child{padding-bottom:0px;}",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export default (function (_ref) {
  var className = _ref.className,
    image = _ref.image,
    description = _ref.description,
    children = _ref.children;
  return ___EmotionJSX("div", {
    className: className,
    css: _ref2
  }, ___EmotionJSX("div", {
    css: _ref3
  }, image || Empty.PRESENTED_IMAGE_SIMPLE), ___EmotionJSX("div", {
    css: _ref4
  }, description || '暂无数据'), children);
});