var _excluded = ["size", "paddingSM", "icon", "iconSize"];
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
import { ConfigProvider, QRCode } from 'antd';
import Image from "./Image";
import { jsx as ___EmotionJSX } from "@emotion/react";
export default (function (_ref) {
  var _ref$size = _ref.size,
    size = _ref$size === void 0 ? 120 : _ref$size,
    _ref$paddingSM = _ref.paddingSM,
    paddingSM = _ref$paddingSM === void 0 ? 8 : _ref$paddingSM,
    icon = _ref.icon,
    _ref$iconSize = _ref.iconSize,
    iconSize = _ref$iconSize === void 0 ? 16 : _ref$iconSize,
    props = _objectWithoutProperties(_ref, _excluded);
  return ___EmotionJSX(ConfigProvider, {
    theme: {
      token: {
        paddingSM: paddingSM
      }
    }
  }, ___EmotionJSX(QRCode, _extends({
    size: size,
    iconSize: iconSize,
    icon: icon && Image.formatUrl(icon, iconSize * 2)
  }, props)));
});