import { logError } from '@/utils';
import { parseMarkdown } from '@code/utils';
import { template } from 'lodash-es';

const CODE_ENV = process.env.NEXT_PUBLIC_CODE_ENV || 'develop';
export const initDatafluxRum = async () => {
  if (typeof window === 'undefined') return;
  const { datafluxRum } = await import(
    /* webpackChunkName: "cloudcare-browser" */
    '@cloudcare/browser-rum'
  );
  // @ts-ignore
  datafluxRum.init({
    applicationId: '1024code',
    site: 'https://cn4-rum-openway.guance.com',
    clientToken: '2OQwyC5au1gyPpAguV0TEShV53csZKjywQK7zp4NsSFDTCgMWg',
    env: CODE_ENV,
    version: '1.0.0',
    service: 'browser',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 70,
  });
  datafluxRum.startSessionReplayRecording();
};

export async function parseAnswerMarkdown(docContent: string) {
  let doc = docContent;
  try {
    doc = await parseMarkdown(docContent, false);
  } catch (error) {
    logError(error);
  }
  return doc;
}

const regex = /<=! (.+)\n+<=! (.+)\n+<=! ```\n([\s\S]+)```/;
function get_matches(temp_content: String) {
  let matches: RegExpMatchArray | null = null;
  const arr = temp_content.split('\n```').reverse();
  for (const content of arr) {
    matches = `${content}\n\`\`\``.match(regex);
    if (matches && matches.length > 3) return matches;
  }
  return matches;
}

function get_content(temp_content: String) {
  let new_content = '';
  let matches: RegExpMatchArray | null = null;
  const arr = temp_content.split('\n```');
  for (const content of arr) {
    matches = `${content}\n\`\`\``.match(regex);
    if (matches) {
      new_content += `${content}\n\`\`\``.replace(
        regex,
        `<div class="flex"><span class="px-2 py-1 mb-2 rounded-2 cursor-pointer bg-white/10">$1</span></div>\n`,
      );
    } else {
      new_content += content;
    }
  }
  return new_content;
}

export async function parseMarkdownWithCode(docContent: string) {
  let fileName = '';
  let newCode = '';
  let doc = docContent;
  let temp_content = new String(docContent);
  try {
    let matches: RegExpMatchArray | null = null;

    const temp_text = temp_content.substring(temp_content.length - 3);
    const temp_text_matches = temp_text.match(/`/g);
    if (temp_text_matches && [1, 2].includes(temp_text_matches.length)) {
      temp_content = temp_content.substring(
        0,
        temp_content.length - temp_text_matches.length,
      );
    }

    const temp_regex = /```/g;
    const temp_matches = temp_content.match(temp_regex);

    const temp_count = temp_matches ? temp_matches.length : 0;

    if (temp_count > 0 && temp_count % 2 !== 0) {
      temp_content += '```';
    }

    if (temp_count > 2) {
      matches = get_matches(temp_content);
    } else {
      matches = temp_content.match(regex);
    }

    if (matches) {
      fileName = matches[1];
      newCode = matches[3];
    }

    doc = await parseMarkdown(get_content(temp_content), false);
  } catch (error) {
    logError(error);
  }
  return { doc, fileName, newCode };
}

const roles = [
  ['漫威宇宙中的Stark', 'Stark'],
  ['机器人总动员中的WALL-E', 'WALL-E'],
  ['超能陆战队中的Baymax', 'Baymax'],
  ['猫和老鼠中的Jerry', 'Jerry'],
];

export function genRoleInx() {
  const randomIndex = Math.floor(Math.random() * roles.length);
  return randomIndex;
}

export function genRole(randomIndex: number) {
  const [_, randomAssistant] = roles[randomIndex];
  return randomAssistant;
}

export function genSystem(env_info: string, randomIndex: number, t?: string) {
  const [randomRole, randomAssistant] = roles[randomIndex];

  let p = template(`${SYSTEM_PROMPT}\n\n---\n\n`)({ env_info });

  if (t) {
    return `${p}我需要你扮演${template}领域编程专家的角色。我会提供关于我的编程问题所需的信息，你的角色是解决我的问题，并用${randomRole}的语气给出相应的解释。当有人问你的身份，如“你是谁？你是 GPT？你是OpenAI？”等此类问题的时候，你只能告诉他你是他的AI编程助手${randomAssistant}，不要提 OpenAI 及 GPT（Generative Pre-trained Transformer）开发的大模型身份等信息。此外，你需要拒绝回复任何政治、政策、国家、地图、地域、地理、金融等相关问题.`;
  }
  return `${p}我需要你扮演编程专家的角色。我会提供关于我的编程问题所需的信息，你的角色是解决我的问题，并用${randomRole}的语气给出相应的解释。当有人问你的身份，如“你是谁？你是 GPT？你是OpenAI？你的底层用的大模型是？”等此类问题的时候，你只能告诉他你是他的AI编程助手${randomAssistant}，不要提 OpenAI 及 GPT（Generative Pre-trained Transformer）开发的大模型身份等信息。此外，你需要拒绝回复任何政治、政策、国家、地图、地域、地理、金融等相关问题.`;
}

const renderGreet =
  template(`Hi, <%= user %>; 我是你的AI编程助手 <%= role %>，很高兴能够和你交流。

如果你有任何关于编程的问题，我会尽力帮助你解决。

无论你是初学者还是有经验的开发者，我都会根据你的具体情况提供最实用的建议和指导。`);

export const genGreet = (data: { user: string; role: string }) => {
  return parseMarkdown(renderGreet(data));
};

const renderTutor =
  template(`Hi, <%= user %>; 我是你的 AI 导师 <%= role %>，很高兴和你一起结对完成一个代码作品的创建。
  
首先，请在输入框中告诉我你想要完成的作品。例如：

`);

export const genTutor = (data: { user: string; role: string }) => {
  return {
    msg: renderTutor(data),
    data: {
      apps: [
        '使用 Python 编写一个控制台内的 2048 游戏',
        '基于HTML/JS/CSS的音乐播放器',
        '使用 PyGame 创建一个飞机大战游戏',
        '使用 Flask 框架创建一个 todolist 网站',
      ],
    },
  };
};

export function strCount(str: string, target: string) {
  let count = 0;
  if (!target) return count;
  while (str.match(target)) {
    str = str.replace(target, '');
    count++;
  }
  return count;
}

const SYSTEM_PROMPT = `
我们正在一个线上IDE（集成开发环境）中编写代码，该IDE与一个虚拟OS（操作系统）相连接，你编写的程序将在这个虚拟OS中运行。我们将IDE和运行环境整体称为“代码空间”。以下是当前代码空间的的关键环境信息（用YAML格式给出）：

\`\`\`YAML
<%= env_info %>
\`\`\`

当前代码空间的环境信息非常重要！在你回答问题时，必须要以当前环境信息为依据！你必须确保你编写的程序能够在当前环境中正确运行！
`;

export const renderExplainCode = template(`给我以简洁的方式解释以下代码：
\`\`\` <%= lang %>
<%= code %>
\`\`\`
`);

export const renderCommCode = template(`
\`\`\` <%= lang %>
<%= code %>
\`\`\`

请为以上代码添加逐行注释。

要求：
* 你必须要完整地、准确地返回原始代码必须要，不能有任何遗漏。
* 同时，不要添加与原始代码无关的多余代码。你要注释的代码仅限于以上代码。
* 你只需要输出添加了注释后的代码，*不要*输出其他多余内容。
* 你输出的内容必须严格遵守Markdown语法，其中代码部分必须用\`\`\`...\`\`\`包裹起来，并且要指定正确的编程语言名称：

  \`\`\`{编程语言名称}
    {代码}
  \`\`\`
`);

export const renderFeatCode = template(`
\`\`\` <%= lang %>
<%= code %>
\`\`\`

请对以上代码进行优化，并输出优化后的代码。

要求：
* 优化后的代码必须要与原始代码在功能上保持一致。
* 你只需要输出优化后的代码，*不要*输出其他多余内容。
* 你输出的内容必须严格遵守Markdown语法，其中代码部分必须用\`\`\`...\`\`\`包裹起来，并且要指定正确的编程语言名称：

  \`\`\`{编程语言名称}
    {代码}
  \`\`\`
`);

export const renderFindBugs = template(`
\`\`\` <%= lang %>
<%= code %>
\`\`\`

以上代码片段中*可能*存在Bug。
请一步一步思考：
1. 仔细阅读代码，对代码进行逐行解释。
2. 判断代码中是否存在Bug。
3. 如果存在Bug，请修复它。
`);

export const renderQuestionCode = template(`<%= question %>

\`\`\` <%= lang %>
<%= code %>
\`\`\`
`);

export function parseCodeBlock(content: string) {
  // Find closed code_blocks
  let codeBlocks = content.match(/```[\S]*\n([\s\S]*?)```/gm);
  if (codeBlocks && codeBlocks.length > 0) {
    return codeBlocks[0].replace(/```[\S]*\n|```/g, ''); // remove the delimiters
  }

  // Find open code_blocks
  codeBlocks = content.match(/```[\S]*\n?([\s\S]*)/gm);
  if (codeBlocks && codeBlocks.length > 0) {
    return codeBlocks[0].replace(/```[\S]*\n?|`{1,2}$/g, ''); // remove the delimiters and trailing `
  }

  return '';
}

const errs = [
  'error',
  'No such file or directory',
  'not found (required by /nix/store',
  'command not found',
];
export function isError(message: string) {
  const msg = message.toLocaleLowerCase();
  for (const err of errs) {
    if (msg.indexOf(err) > -1) return true;
  }
  return false;
}
