import { Button, Image } from '@code/ui';
import Img from 'next/image';
import { ReactNode, useMemo } from 'react';

interface ResultPageProps {
  status?: '404' | '500';
  description?: ReactNode;
  extra?: ReactNode;
}

const map = {
  404: { description: '抱歉，你要找的页面不存在', icon: 'assets/404.png' },
  500: { description: '抱歉，网站出了些问题', icon: 'assets/500.png' },
} as const;

export const ResultPage = ({
  status = '404',
  extra = (
    <Button block replace type="primary" href="/~">
      返回首页
    </Button>
  ),
  ...props
}: ResultPageProps) => {
  const { description, icon } = useMemo(() => {
    let description: ReactNode, icon: string;
    ({ description, icon } = map[status]);
    if (props.description) {
      ({ description } = props);
    }
    return { description, icon };
  }, [status, props.description]);

  return (
    <div className="w-302 h-400 absolute top-0 left-0 right-0 bottom-0 m-auto">
      <Img width={302} height={180} src={Image.formatUrl(icon)} alt={status} />
      <div className="mt-5 text-center text-sm text-foreground-2">
        {description}
      </div>
      <div className="mt-7.5 py-0 px-7.5">{extra}</div>
    </div>
  );
};
