export const scenesRoutes = [
  '/site/learn',
  '/site/intelligent',
  '/site/summarize',
  '/site/collection',
];

export const siteRoutes = [
  '/',
  '/site/m',
  '/remote/secretAgreement',
  '/remote/serviceArticle',
  ...scenesRoutes,
];

export const authRoutes = [
  '/login',
  '/signup',
  '/signup/code',
  '/signup/foster',
];

export const NotAccessRoutes = [...authRoutes, '/invitation-code/apply'];

export const CSRRoutes = [...siteRoutes];

export const YOUTHCAMP = {
  hasJoin: true,
  hasExit: false,
  // useTeams: [
  //   'qingxun_fe',
  //   'qingxun_fe_adv',
  //   'qingxun_be',
  //   'qingxun_be_adv',
  // ] as string[],
} as const;

export const OP_IN_PROGRESS = ['in_progress', 'ignited'];
export const OP_LOAD = [...OP_IN_PROGRESS, 'suspended'];

export const ROOT_PATH = '/home/runner/app';

export const AGENT_TIMEOUT = 60 * 1000;
export const EXEC_TIMEOUT = 90 * 1000;
export const PAAS_EXEC_TIMEOUT = 5 * 1000;
export const RUN_APP_TIMEOUT = 5 * 1000;

export const ITERATION_POINTS = 100;
export const MVP_POINTS = 300;
