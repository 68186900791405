import { useContext } from '@/store';
import { useRequest } from '@code/hooks';
import request from '@code/request';
import { loginUrl, Track } from '@code/utils';
import { useRouter } from 'next/router';

interface Params {
  slug: string;
  onSuccess?(): void;
}

export function useFollowUser({ slug, onSuccess }: Params) {
  const router = useRouter();
  const { messageApi, isSignIn } = useContext();
  const followReq = useRequest(() => request.post(`/follows/${slug}`), {
    manual: true,
    debounceWait: 300,
    onSuccess() {
      Track.capture('用户关注事件');
      messageApi.success('关注成功');
      onSuccess?.();
    },
  });
  const onFollow = () => {
    if (!isSignIn) {
      router.push(loginUrl());
      return messageApi.warning('请先登录');
    }
    followReq.run();
  };
  return [followReq, onFollow] as const;
}

export function useUnFollowUser({ slug, onSuccess }: Params) {
  const router = useRouter();
  const { messageApi, isSignIn } = useContext();
  const unFollowReq = useRequest(() => request.delete(`/follows/${slug}`), {
    manual: true,
    debounceWait: 300,
    onSuccess() {
      messageApi.success('取关成功');
      onSuccess?.();
    },
  });
  const onUnFollow = () => {
    if (!isSignIn) {
      router.push(loginUrl());
      return messageApi.warning('请先登录');
    }
    unFollowReq.run();
  };
  return [unFollowReq, onUnFollow] as const;
}
