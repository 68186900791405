import { getUserInfo } from '@/api/user';
import { initEnvVar, logError } from '@/utils';
import { RAILS_CABLE } from '@code/constant';
import {
  EventEmitter,
  getMobile,
  useEventEmitter,
  useMount,
  useUpdateEffect,
} from '@code/hooks';
import request from '@code/request';
import { Dialog, HookDialogApi, MessageApi, useMessage } from '@code/ui';
import { checkSvg, clipboardSvg, log, sleep, Token, Track } from '@code/utils';
import copy from 'copy-to-clipboard';
import { enableStaticRendering, useLocalObservable } from 'mobx-react-lite';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import useSWR from 'swr';
import vhCheck from 'vh-check';
import { createStore, RootStore } from './rootStore';
import { Consumer } from './types';
import { initDatafluxRum } from './utils';

export interface IAppContext {
  dialogApi: HookDialogApi;
  messageApi: MessageApi;
  consumer: Consumer | null;
  event$: EventEmitter<{ type: string; data?: any }>;
  isMobile: boolean;
  isBrowser: boolean;
  isSignIn: boolean;
  userInfo?: User.Info;
  deviceInfo: DeviceInfo;
  mutateUser: () => void;
}

export interface DeviceInfo {
  isMac: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  isMobile: boolean;
  isNoIOSAndroid: boolean;
}

export const AppContext = React.createContext<IAppContext>({} as any);

export const AppProvider = AppContext.Provider;

export function useContext() {
  return React.useContext(AppContext);
}

interface Props {
  deviceInfo: DeviceInfo;
  children?: ReactNode;
}

enableStaticRendering(typeof window === 'undefined');

const StoreContext = createContext({} as RootStore);

export const StoreProvider = (props: Props) => {
  const router = useRouter();

  const [isSignIn, setIsSignIn] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(props.deviceInfo);
  const [isMobile, setMobile] = useState(!!props.deviceInfo?.isMobile);
  const [consumer, setConsumer] = useState<Consumer | null>(null);
  const [isBrowser, setBrowser] = useState(false);
  const [dialogApi, contextHolder] = Dialog.useDialog();
  const [messageApi, msgContextHolder] = useMessage();
  const event$ = useEventEmitter<{ type: string; data?: any }>();
  const { data: userInfo, mutate: mutateUser } = useSWR(
    '/profile',
    async () => {
      if (typeof window === 'undefined') return undefined;
      if (!Token.isSignIn) return undefined;
      let userInfo: User.Info | undefined = undefined;
      try {
        userInfo = await getUserInfo();
      } catch (error) {
        logError(error);
      }
      return userInfo;
    },
  );
  const store = useLocalObservable(
    createStore(messageApi, dialogApi, isMobile, userInfo),
  );

  const initConsumer = async () => {
    if (!Token.isSignIn) return;
    const { createConsumer } = await import(
      /* webpackChunkName: "rails_actioncable" */
      '@rails/actioncable'
    );
    await sleep(300);
    const consumer = createConsumer(`${RAILS_CABLE}?auth_token=${Token.token}`);

    store.codecube.setConsumer(consumer);
    setConsumer(consumer);

    consumer.subscriptions.create(
      { channel: 'NotificationsChannel' },
      {
        received: (data) => {
          log('NotificationsChannel', data);
          event$.emit({ type: 'receivedNotifications' });
        },
      },
    );
  };

  useEffect(() => {
    vhCheck();
    mutateUser();
    initConsumer();
    initDatafluxRum();
    initEnvVar(window.location.host, {}, {});
    Track.init(() => event$.emit({ type: 'initTrack' }));
    store.community.init();
    request.message = messageApi;

    window.CODE_DEBUG = router.query?.debug === '1';

    Token.update();
    setBrowser(true);
    setMobile(getMobile());
    setIsSignIn(Token.isSignIn);
    store.codecube.setMobile(getMobile());

    // fix iOS13 iPad的userAgent异常问题 https://juejin.cn/post/6865658712665620494
    const isIOS =
      deviceInfo.isIOS || (deviceInfo.isMac && navigator.maxTouchPoints > 1);
    const isMobile = isIOS || deviceInfo.isAndroid || deviceInfo.isNoIOSAndroid;

    setDeviceInfo({ ...deviceInfo, isIOS, isMobile });

    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });
    router.events.on('routeChangeComplete', () => {
      NProgress.done();
      setIsSignIn(Token.isSignIn);
      Track.capture('$pageview');
      event$.emit({ type: 'initTrack' });
    });
    router.events.on('routeChangeError', () => {
      NProgress.done();
    });

    return () => {
      consumer?.disconnect();
      setConsumer(null);
    };
  }, []);

  useEffect(() => {
    const listenerClick = (e: any) => {
      const targetButton = e.target.closest('button');
      if (targetButton?.classList?.contains('code-element-gnc')) {
        e.preventDefault();
        const code =
          targetButton.parentElement?.parentElement?.parentElement?.lastChild
            ?.textContent;
        if (!code) return;
        if (copy(code)) {
          targetButton.innerHTML = `${checkSvg} Copied`;

          setTimeout(() => {
            targetButton.innerHTML = `${clipboardSvg} Copy code`;
          }, 1500);
        }

        return;
      }
    };

    window.addEventListener('click', listenerClick);
    return () => {
      window.removeEventListener('click', listenerClick);
    };
  }, []);

  useUpdateEffect(() => {
    store.routerStore.push(router.pathname, router.asPath);
  }, [router.asPath]);

  useMount(() => {
    window.addEventListener(
      'popstate',
      function (event) {
        if (!event.state) return;
        const { url, as } = event.state;
        store.routerStore.judge(url, as);
      },
      false,
    );

    if (['/subjects/[id]'].includes(router.pathname)) {
      store.routerStore.push('/community', '/community');
    }
    if (['/[mousername]/[slug]'].includes(router.pathname)) {
      isSignIn
        ? store.routerStore.push('/', '/')
        : store.routerStore.push('/community', '/community');
    }

    store.routerStore.push(router.pathname, router.asPath);
  });

  useEffect(() => {
    store.codecube.setUserInfo(userInfo || ({} as any));

    if (userInfo) {
      Track.identify(userInfo.user_id, {
        phone: userInfo.phone,
        name: userInfo.name,
        email: userInfo.email,
        come_from: userInfo.come_from,
      });
    }
  }, [userInfo?.user_id]);

  event$.useSubscription(({ type }) => {
    if (type === 'login') {
      initConsumer();
      mutateUser();
      setIsSignIn(Token.isSignIn);
    }

    if (type === 'logout') {
      consumer?.disconnect();
      setConsumer(null);
    }
  });

  if (
    !isBrowser &&
    ['/ide/[...slug]', '/collections/[...slug]'].includes(router.route)
  ) {
    // TODO 目前针对IDE和合集不做SEO，有Bug
    return null;
  }

  return (
    <AppProvider
      value={{
        event$,
        consumer,
        dialogApi,
        messageApi,
        isMobile,
        isBrowser,
        isSignIn,
        userInfo,
        deviceInfo,
        mutateUser,
      }}
    >
      <StoreContext.Provider value={store}>
        {contextHolder}
        {msgContextHolder}
        {props.children}
      </StoreContext.Provider>
    </AppProvider>
  );
};

export const useStore = () => {
  const store = React.useContext(StoreContext);
  if (!store) {
    throw new Error('数据不存在');
  }
  return store;
};
