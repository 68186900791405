import { scenesRoutes } from '@/constant';
import { useContext } from '@/store';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useMemo } from 'react';
import { Comminicate } from './Comminicate';
import { Header } from './Header';

export interface MainProps {
  children?: React.ReactNode;
}

export const Main = ({ children }: MainProps) => {
  const router = useRouter();
  const { isMobile, isSignIn } = useContext();
  const full = useMemo(() => {
    if (
      [
        '/code',
        '/foster',
        '/embed-ide',
        '/ide/[...slug]',
        '/[mousername]/[slug]',
        '/collections/[...slug]',
        '/invitation-code/apply',
      ].includes(router.route)
    ) {
      return true;
    }
    return isSignIn ? false : ['/'].includes(router.route);
  }, [router.route, isSignIn]);
  const isDocs = useMemo(
    () => scenesRoutes.includes(router.route),
    [router.route],
  );
  const hasHeader = useMemo(() => {
    if (['/embed-ide', '/ide/[...slug]'].includes(router.pathname)) {
      return false;
    }
    if (['/collections/[...slug]'].includes(router.pathname)) {
      return !!isMobile;
    }
    if (['/search'].includes(router.pathname)) {
      return !isMobile;
    }
    return true;
  }, [router.route, isMobile]);

  const renderContent = () => {
    if (full) return children;
    if (isDocs) {
      return (
        <div className="w-full max-w-[768px] mx-auto flex flex-col">
          {children}
        </div>
      );
    }
    return (
      <div className="w-full max-w-[1168px] mx-auto flex flex-col">
        {children}
      </div>
    );
  };

  return (
    <>
      {!!router.query?.console && (
        <Script
          id="vconsole"
          src="https://unpkg.com/vconsole@latest/dist/vconsole.min.js"
          strategy="afterInteractive"
          onLoad={() => {
            // @ts-ignore
            var vConsole = new VConsole();
          }}
        />
      )}
      <Header hasHeader={hasHeader} />
      <Comminicate />
      <div
        className={classNames(
          'w-full relative',
          hasHeader ? 'min-h-main-vh' : 'min-h-vh lg:min-h-main-vh',
        )}
      >
        {renderContent()}
      </div>
    </>
  );
};
