import { storage } from '@code/utils';
import { pick, throttle } from 'lodash-es';
import { makeAutoObservable } from 'mobx';

const CURRENTCODECUBE_KEY = '1024app_currentCodecube';

export class CommunityStore {
  draftCodecube: Codecubes.Info | null = null;
  currentCodecube: Codecubes.Info | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  init = () => {
    if (typeof window !== 'undefined') {
      const currentCodecubeStr = storage.getItem(CURRENTCODECUBE_KEY);
      if (currentCodecubeStr) {
        this.draftCodecube = JSON.parse(currentCodecubeStr);
      }
    }
  };

  updateCodecube = throttle((currentCodecube: Codecubes.Info | null) => {
    if (!currentCodecube) {
      this.draftCodecube = null;
      this.currentCodecube = null;
      if (typeof window !== 'undefined')
        storage.removeItem(CURRENTCODECUBE_KEY);
      return;
    }
    const tag_ids = currentCodecube.tag_ids
      ? currentCodecube.tag_ids
      : currentCodecube.tags
      ? currentCodecube.tags
          ?.map((tag) => !!tag.tag_group_id && [tag.tag_group_id, tag.id])
          .filter((t) => !!t) || []
      : this.currentCodecube?.tag_ids || [];

    this.currentCodecube = {
      ...this.currentCodecube,
      ...(pick(currentCodecube, [
        'slug',
        'name',
        'description',
        'code_zone_id',
        'codecube_cover',
        'is_publish_approved',
      ]) as Codecubes.Info),
      tag_ids,
    };

    storage.setItem(CURRENTCODECUBE_KEY, JSON.stringify(this.currentCodecube));
  }, 300);
}
