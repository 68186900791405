import { Image } from '@code/ui';

export const cooperations = [
  {
    key: 'cooperation',
    nextKey: 'run',
    title: '多人协同',
    desc: '实时的代码和项目编辑协同',
    animationDataUrl: '/animations/page-2-1.json',
    assetsPath: Image.formatUrl('assets/animations/page-2-1/'),
  },
  {
    key: 'run',
    nextKey: 'follow',
    title: '在线运行',
    desc: '任何语言、大小项目',
    animationDataUrl: '/animations/page-2-2.json',
    assetsPath: Image.formatUrl('assets/animations/page-2-2/'),
  },
  {
    key: 'follow',
    nextKey: 'cooperation',
    title: '视角跟随',
    desc: '任何设备，即刻开始',
    animationDataUrl: '/animations/page-2-3.json',
    assetsPath: Image.formatUrl('assets/animations/page-2-3/'),
  },
];

export interface HomePageProps {
  collection: Collections.Info;
  collections: Collections.Info[];
  items: Codecubes.Info[];
  users: User.Info[];
}
