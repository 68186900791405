import { PM_WXURL } from '@/utils';
import { CodeCodeRefresh } from '@code/icons';
import { Button, Collapse, HookDialogApi, MessageApi } from '@code/ui';
import { makeAutoObservable, runInAction } from 'mobx';
import QRCode from 'qrcode.react';
import { ParsedUrlQuery } from 'querystring';
import type IDEStore from './IDEStore';
import { Consumer } from './types';

export interface Params {
  slug: string;
  source?: string;
  hasYJL: boolean;
  query: ParsedUrlQuery;
  isPublish: boolean | null;

  collection?: Collections.Info;
  collectionInx?: number;
}

export type SlugParams = Pick<
  Params,
  'query' | 'hasYJL' | 'slug' | 'collection' | 'collectionInx'
>;

interface CreateErrorInfo {
  createType: Codecubes.CreateType;
  createData: Record<string, any>;
  error: Record<string, any>;
}

export class CodecubeStore {
  ideStore: IDEStore = null as any;

  messageApi: MessageApi;
  dialogApi: HookDialogApi;

  consumer: Consumer | null = null;
  userInfo: User.Info;

  isMobile = false;

  createType: Codecubes.CreateType | null = null;
  createData: Record<string, any> | null = null;
  createError: CreateErrorInfo | null = null;

  constructor(
    messageApi: MessageApi,
    dialogApi: HookDialogApi,
    isMobile: boolean,
    userInfo: User.Info = {} as any,
  ) {
    this.isMobile = isMobile;
    this.dialogApi = dialogApi;
    this.messageApi = messageApi;
    this.userInfo = userInfo;
    makeAutoObservable(this);
  }

  before_create = (type: Codecubes.CreateType, data: Record<string, any>) => {
    this.createType = type;
    this.createData = data;
    return { codecube_slug: 'creating' };
  };

  createErrorByCancel = () => {
    this.ideStore?.clearCreateInfo();
    this.createType = null;
    this.createData = null;
    this.createError = null;
  };
  showError = (
    createCodecube: (
      template?: Codecubes.Template | null | undefined,
      type?: Codecubes.CreateType | undefined,
      createData?: Record<string, any> | undefined,
    ) => void,
  ) => {
    if (!this.createError) return;
    const { createData, createType, error } = this.createError;
    const { destroy } = this.dialogApi.result({
      closable: false,
      maskClosable: false,
      footer: null,
      resultProps: {
        status: 'warning',
        title: '创建失败',
        extra: (
          <div className="w-full flex flex-col items-center">
            <div className="flex items-center">
              <Button
                className="mr-4"
                onClick={() => {
                  this.createErrorByCancel();
                  destroy();
                }}
              >
                取消创建
              </Button>
              <Button
                type="primary"
                icon={<CodeCodeRefresh />}
                onClick={() => {
                  createCodecube(null, createType, createData);
                  destroy();
                }}
              >
                重新创建
              </Button>
            </div>
            <div className="mt-6 w-full flex flex-col items-center p-4 rounded-2 bg-black/20">
              <div className="mb-3">依旧创建失败？请联系运营同学寻求帮助</div>
              <div className="w-21 h-21 bg-white rounded-1 flex items-center justify-center">
                <QRCode value={PM_WXURL} size={76} fgColor="#000000" />
              </div>
              <div className="mt-2">微信扫码添加/联系</div>
            </div>
            <div className="mt-6 w-full">
              <Collapse expandIconPosition="right">
                <Collapse.Panel header="查看具体错误信息" key="1">
                  <div>{JSON.stringify(error, null, 2)}</div>
                </Collapse.Panel>
              </Collapse>
            </div>
          </div>
        ),
      },
    });
  };

  setMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  };
  setConsumer = (consumer: Consumer) => {
    this.consumer = consumer;
    this.ideStore?.setConsumer(consumer);
  };
  setUserInfo = (userInfo: User.Info) => {
    this.userInfo = userInfo;
    this.ideStore?.updateTitle();
  };

  onSlugChange = (params: SlugParams) => {
    this.ideStore?.onSlugChange(params);
  };

  mount = async (params: Params) => {
    const { default: IDEStore } = await import(
      /* webpackChunkName: "ide_store" */
      './IDEStore'
    );
    runInAction(() => {
      this.ideStore = new IDEStore(this.dialogApi, this);
      this.ideStore?.mount(params);
    });
  };

  unmount = () => {
    this.ideStore?.unmount();
  };
}
