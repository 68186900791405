import { useContext } from '@/store';
import { Statistic } from '@/ui';
import { logError } from '@/utils';
import {
  AnimationConfigWithPath,
  AnimationItem,
  loadAnimation,
} from '@/utils/lottie-web';
import { useUpdate, useUpdateEffect } from '@code/hooks';
import {
  CodeGo,
  CodeShowFullOutlined,
  CodeSpacePraiseOutlined,
  CodeTeamSpace,
  CollectionCoverHPlate,
  CollectionCoverPlate,
} from '@code/icons';
import { Copyable, Image, Link, QRCode } from '@code/ui';
import classNames from 'classnames';
import { throttle } from 'lodash-es';
import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { cooperations } from './types';

export const useLottie = (mobile = false) => {
  const update = useUpdate();
  const selectedRef = useRef('cooperation');
  const animationsRef = useRef<Record<string, AnimationItem>>({});
  useEffect(() => {
    cooperations.forEach(({ key, nextKey, animationDataUrl, assetsPath }) => {
      loadAnimation({
        path: animationDataUrl,
        container: document.getElementById(`page-2-video-${key}`)!,
        loop: false,
        autoplay: false,
        assetsPath,
      })
        .then((animation) => {
          const progressElKey = `#page-2-${key} .progress`;

          const updateProgress = throttle(() => {
            if (selectedRef.current !== key) return;
            const el = document.querySelector(progressElKey) as HTMLDivElement;
            const progressWidth =
              animation.currentFrame / animation.totalFrames;
            if (!isNaN(progressWidth) && el) {
              el.style.cssText = mobile
                ? `width: ${progressWidth * 32}px`
                : `height: ${progressWidth * 48}px`;
            }

            if (progressWidth > 0.99) {
              setSelected(nextKey);
              setTimeout(() => {
                const el = document.querySelector(
                  progressElKey,
                ) as HTMLDivElement;
                if (el) el.style.cssText = '';
              }, 100);
            }
          }, 66);

          animation.addEventListener('enterFrame', updateProgress);

          animationsRef.current[key] = animation;
          if (key === 'cooperation') animation.play();
        })
        .catch((error) => {
          logError(error);
        });
    });
    return () => {
      Object.keys(animationsRef.current).forEach((key) => {
        animationsRef.current[key]?.destroy();
      });
    };
  }, []);

  const setSelected = (key: string) => {
    if (key === selectedRef.current) return;
    const animation = animationsRef.current[selectedRef.current];
    animation?.stop();
    selectedRef.current = key;
    update();
  };

  useUpdateEffect(() => {
    const animation = animationsRef.current[selectedRef.current];
    animation?.play();
  }, [selectedRef.current]);

  return { selected: selectedRef.current, setSelected } as const;
};

type LottieAnimationPeops = Omit<
  AnimationConfigWithPath,
  'container' | 'renderer' | 'path'
> & {
  id?: string;
  width: number;
  height: number;
  mobile?: boolean;
  className?: string;
  animationDataUrl: string;
  progress_selectors?: string;
};

export const LottieAnimation = forwardRef<
  AnimationItem | undefined,
  LottieAnimationPeops
>(
  (
    {
      id,
      width,
      height,
      mobile,
      className,
      animationDataUrl,
      progress_selectors,
      ...params
    },
    ref,
  ) => {
    const scale = useMemo(() => (height / width) * 100, [width, height]);
    const containerRef = useRef<HTMLDivElement>(null);
    const animationRef = useRef<AnimationItem>();
    const [load, set] = useState(false);

    useEffect(() => {
      const updateProgress = throttle(() => {
        const animation = animationRef.current;
        if (!animation) return;
        if (!progress_selectors) return;

        const el = document.querySelector(progress_selectors) as HTMLDivElement;
        const progressWidth = animation.currentFrame / animation.totalFrames;
        if (!isNaN(progressWidth) && el) {
          el.style.cssText = mobile
            ? `width: ${progressWidth * 32}px`
            : `width: ${progressWidth * 48}px`;
        }
      }, 66);
      loadAnimation({
        container: containerRef.current!,
        path: animationDataUrl,
        ...params,
      })
        .then((animation) => {
          set(true);

          if (progress_selectors)
            animation.addEventListener('enterFrame', updateProgress);

          animationRef.current = animation;
        })
        .catch((error) => {
          logError(error);
        });
      return () => {
        const animation = animationRef.current;
        if (progress_selectors)
          animation?.removeEventListener('enterFrame', updateProgress);

        animation?.destroy();
      };
    }, []);

    useImperativeHandle(ref, () => animationRef.current);

    return (
      <div
        id={id}
        className={classNames(className, '')}
        ref={containerRef}
        style={{ paddingBottom: load ? 0 : `${scale}%` }}
      ></div>
    );
  },
);

interface CodecubeItemProps {
  item: Codecubes.Info;
}
export const CodecubeItem = ({ item }: CodecubeItemProps) => {
  if (!item) return null;
  return (
    <Link
      target="_blank"
      href={`/codecubes/${item.slug}`}
      className="group cursor-pointer mb-6 lg:mb-8 last:mb-0 p-4 w-full rounded-4 flex flex-col items-start border border-solid border-transparent bg-white/10 lg:bg-white/5 hover:bg-white/0 hover:border-accent-primary hover:bg-gradient-to-t from-accent-primary/20 via-accent-primary/0 to-accent-primary/0"
    >
      <div className="mb-3 flex items-center">
        <Image
          width={32}
          height={32}
          lazy
          pure
          circle
          className="w-6 h-6 text-4 mr-2"
          src={item.creator.avatar}
          alt="用户头像"
        />
        <div className="text-xs text-foreground-1">{item.creator.name}</div>
      </div>
      <div className="w-full h-10 mb-3 line-clamp-2 text-sm text-foreground-2">
        {item.description}
      </div>
      <Image
        width={1600}
        height={900}
        lazy
        pure
        className="w-full h-[171px] mt-4 text-5"
        src={item.codecube_cover}
        alt={item.name}
      />
    </Link>
  );
};

interface CollectionItemProps {
  item: Collections.Info;
}
export const CollectionItem = ({ item }: CollectionItemProps) => {
  if (!item) return null;
  return (
    <Link
      target="_blank"
      href={`/collections/${item.slug || item.id}`}
      className="group cursor-pointer mb-6 lg:mb-8 last:mb-0 py-2 px-3 w-full rounded-4 flex flex-col items-start bg-white/10 lg:bg-white/5 hover:bg-white/10"
    >
      <div className="w-full h-9 flex items-center">
        <div className="text-5 mr-3">
          <div className="block group-hover:hidden">
            <CollectionCoverPlate />
          </div>
          <div className="hidden group-hover:block">
            <CollectionCoverHPlate />
          </div>
        </div>
        <div className="flex-1 truncate text-sm font-semibold text-foreground-1">
          {item.name}
        </div>
      </div>

      <div className="pl-8 pb-2 w-full flex flex-col">
        <div className="w-full h-10 mb-3 line-clamp-2 text-xs text-foreground-2">
          {item.short_desc}
        </div>
        <div className="w-full flex items-center">
          <div className="flex-1 flex items-center text-xs text-foreground-2">
            <div className="text-3.5 mr-1">
              <CodeShowFullOutlined />
            </div>
            <Statistic value={item.visits_count} />
          </div>
          <div className="flex-1 flex items-center text-xs text-foreground-2">
            <div className="text-3.5 mr-1">
              <CodeSpacePraiseOutlined />
            </div>
            <Statistic value={item.likes_count} />
          </div>
          <div className="flex-1 flex items-center text-xs text-foreground-2">
            <div className="text-3.5 mr-1">
              <CodeTeamSpace />
            </div>
            <Statistic value={item.codecubes_count} />
          </div>
        </div>
      </div>
    </Link>
  );
};

export const IDECollectionItem = ({ item }: CollectionItemProps) => {
  if (!item) return null;
  return (
    <div className="mb-6 lg:mb-8 last:mb-0 p-4 w-full rounded-4 flex flex-col items-start bg-white/10 lg:bg-white/5">
      <div className="mb-2 font-medium text-sm text-foreground-1">合集</div>
      <Link
        target="_blank"
        href={`/collections/${item.slug || item.id}`}
        className="mb-4 p-3 rounded-2 w-full flex flex-col items-start bg-black/20 hover:bg-black/40"
      >
        <div className="mb-2 font-bold text-sm text-foreground-1">
          {item.name}
        </div>
        <div className="text-xs font-normal text-foreground-2 line-clamp-3">
          {item.short_desc}
        </div>
      </Link>
      <div className="p-3 rounded-2 w-full flex flex-col items-start bg-black/20">
        <div className="mb-3 font-normal text-sm text-foreground-1">
          合集空间列表
        </div>
        {item.codecubes.slice(0, 3).map((codecube) => (
          <Link
            target="_blank"
            key={codecube.slug}
            href={`/codecubes/${codecube.slug}`}
            className="cursor-pointer mb-4 last:mb-0 p-2 w-full rounded-2 flex items-center bg-white/10 lg:bg-white/5 hover:bg-white/10"
          >
            <Image
              width={1600}
              height={900}
              pure
              lazy
              className="w-[84px] h-[48px] text-5 flex-none mr-3"
              src={codecube.codecube_cover}
              alt={codecube.name}
            />
            <div className="flex flex-col w-full overflow-hidden">
              <div className="mb-2 truncate text-sm font-medium text-foreground-1">
                {codecube.name}
              </div>
              <div className="truncate text-xs font-normal text-foreground-2">
                {codecube.description}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

interface UsersProps {
  users: User.Info[];
}
export const Users = ({ users }: UsersProps) => {
  return (
    <div className="mb-6 lg:mb-8 last:mb-0 w-full p-2 rounded-4 flex flex-col bg-white/10 lg:bg-white/5">
      {users.map((user) => (
        <Link
          key={user.slug}
          target="_blank"
          href={`/~${user.slug}`}
          className="flex items-center group relative p-3 rounded-3 cursor-pointer hover:bg-white/10"
        >
          <Image
            pure
            lazy
            circle
            width={32}
            height={32}
            src={user.avatar}
            alt={user.name}
            className="w-[32px] h-[32px]"
          />
          <div className="flex-1 flex flex-col items-start ml-3 overflow-hidden">
            <div className="mb-1 text-sm text-foreground-1">{user.name}</div>
            <div className="w-full overflow-hidden flex items-center">
              <div className="w-[80px] flex items-center truncate text-xs text-foreground-2">
                <div className="mr-1">发布空间</div>
                <div className="truncate text-foreground-1">
                  <Statistic value={user.publication_count} />
                </div>
              </div>
              <div className="w-[80px] ml-2 flex items-center truncate text-xs text-foreground-2">
                <div className="mr-1">公开空间</div>
                <div className="truncate text-foreground-1">
                  <Statistic value={user.public_count} />
                </div>
              </div>
            </div>
          </div>
          <div className="absolute top-1/2 right-3 -translate-y-1/2 text-5 text-foreground-2 opacity-0 group-hover:opacity-100">
            <CodeGo />
          </div>
        </Link>
      ))}
    </div>
  );
};

export const ShareQRCode = () => {
  const { isMobile } = useContext();
  const text = `https://1024code.com/codecubes/BNXbkR5 《2048游戏》，请点击链接或复制链接到浏览器打开，在线查看和运行`;
  return (
    <div className="mb-6 lg:mb-8 last:mb-0 w-full p-3 lg:px-6 lg:py-4 rounded-4 bg-white/10 lg:bg-white/5 flex items-start">
      <div className="flex flex-col mr-6">
        <div className="mb-2 text-sm text-foreground-1">扫码分享</div>
        <QRCode
          className="opacity-80"
          paddingSM={4}
          color="#1BB380"
          value="https://1024code.com/codecubes/BNXbkR5"
          size={isMobile ? 60 : 84}
        />
      </div>
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="mb-2 text-sm text-foreground-1">链接分享</div>
        <div className="px-3 py-2 rounded-2 flex flex-col items-end bg-white/10 lg:bg-white/5 overflow-hidden">
          <div className="mb-1 w-full text-sm text-foreground-2 break-all line-clamp-1 lg:line-clamp-2">
            {text}
          </div>
          <Copyable.Button icon={<></>} text={text} type="link">
            复制链接
          </Copyable.Button>
        </div>
      </div>
    </div>
  );
};

type PinSpacerProps = PropsWithChildren<{
  id: string;
  end?: boolean;
  className?: string;
  height: number | string;
  minHeight?: number | string;
}>;
export const PinSpacer = ({
  id,
  end,
  height,
  minHeight,
  className,
  children,
}: PinSpacerProps) => {
  if (end)
    return (
      <div id={id} className={className}>
        {children}
      </div>
    );
  return (
    <div id={id} className="w-full" style={{ height, minHeight }}>
      <div className="sticky top-0 w-full h-vh overflow-hidden">
        <div
          className={classNames(
            'absolute bottom-0 left-0 w-screen overflow-hidden h-vh',
          )}
        >
          <div className={classNames('absolute w-full h-full', className)}>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
