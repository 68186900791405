var PAAS_DOMAIN = process.env.NEXT_PUBLIC_PAAS_DOMAIN || 'https://www.1024paas.com';
var RAILS_PROTOCOL = process.env.NEXT_PUBLIC_RAILS_PROTOCOL || 'https';
var RAILS_HOST = process.env.NEXT_PUBLIC_RAILS_HOST || '1024code.com';
var RAILS_CABLE_PROTOCOL = RAILS_PROTOCOL === 'https' ? 'wss' : 'ws';
var RAILS_API = "".concat(RAILS_PROTOCOL, "://").concat(RAILS_HOST, "/api/v1");
var RAILS_CABLE = "".concat(RAILS_CABLE_PROTOCOL, "://").concat(RAILS_HOST, "/cable");
export var initEnv = function initEnv(params) {
  PAAS_DOMAIN = params.PAAS_DOMAIN || PAAS_DOMAIN;
  RAILS_PROTOCOL = params.RAILS_PROTOCOL || RAILS_PROTOCOL;
  RAILS_HOST = params.RAILS_HOST || RAILS_HOST;
  RAILS_CABLE_PROTOCOL = RAILS_PROTOCOL === 'https' ? 'wss' : 'ws';
  RAILS_API = "".concat(RAILS_PROTOCOL, "://").concat(RAILS_HOST, "/api/v1");
  RAILS_CABLE = "".concat(RAILS_CABLE_PROTOCOL, "://").concat(RAILS_HOST, "/cable");
};
export { PAAS_DOMAIN, RAILS_API, RAILS_CABLE };