import Cookies from 'js-cookie';
import { storage } from "./storage";
export var TOKEN = '1024app_token';
export var Token = {
  get TOKEN() {
    return TOKEN;
  },
  get token() {
    return storage.getItem(TOKEN);
  },
  set: function set(token) {
    storage.setItem(TOKEN, token);
    this.update();
  },
  update: function update() {
    if (this.token) Cookies.set(TOKEN, this.token, {
      expires: 30,
      secure: true,
      sameSite: 'Lax'
    });
  },
  remove: function remove() {
    if (typeof window === 'undefined') return;
    if (window.self !== window.top) return;
    if (!window.navigator.cookieEnabled) return;
    Cookies.remove(TOKEN);
    return storage.removeItem(TOKEN);
  },
  get isSignIn() {
    return !!this.token;
  }
};