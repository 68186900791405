import { Button, ButtonProps } from '@code/ui';
import { loginUrl } from '@code/utils';
import { useRouter } from 'next/router';

type LoginButtonProps = Omit<ButtonProps, 'href'>;

export const LoginButton = ({
  children,
  onClick,
  ...props
}: LoginButtonProps) => {
  const router = useRouter();
  const handleClick = (event: any) => {
    onClick?.(event);
    return router.push(loginUrl());
  };
  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  );
};
