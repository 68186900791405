import { HookDialogApi, MessageApi } from '@code/ui';
import { CodecubeStore } from './codecubeStore';
import { CommentStore } from './commentStore';
import { CommunityStore } from './communityStore';
import { RouterStore } from './routerStore';

export interface RootStore {
  comment: CommentStore;
  codecube: CodecubeStore;
  routerStore: RouterStore;
  community: CommunityStore;
}
export function createStore(
  messageApi: MessageApi,
  dialogApi: HookDialogApi,
  isMobile: boolean,
  userInfo?: User.Info,
): () => RootStore {
  return () => {
    return {
      comment: new CommentStore(),
      routerStore: new RouterStore(),
      community: new CommunityStore(),
      codecube: new CodecubeStore(messageApi, dialogApi, isMobile, userInfo),
    };
  };
}
