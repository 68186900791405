import {
  notificationsList,
  notificationsRead,
  notificationsReadAll,
} from '@/api/notifications';
import { sleep } from '@code/utils';
import { makeAutoObservable } from 'mobx';

export class NotificationStore {
  visible = false;
  iconLoading = false;
  newNotifications: any[] = [];
  readNotifications: any[] = [];
  notificationsTotal = 0;

  idLtNew: string | null = null;
  idLtRead: string | null = null;

  time: number | null = null;

  constructor() {
    makeAutoObservable(this);
    this.getNotifications();
  }

  receivedNotifications = () => {
    if (!this.visible) this.getNewNotifications();
  };

  setVisible = (visible: boolean) => {
    this.visible = visible;
  };
  setIconLoading = (iconLoading: boolean) => {
    this.iconLoading = iconLoading;
  };
  setIdLtNew = (idLtNew: string | null) => {
    this.idLtNew = idLtNew;
  };
  setIdLtRead = (idLtRead: string | null) => {
    this.idLtRead = idLtRead;
  };
  setNewNotifications = (notifications: any[]) => {
    if (!notifications) return;
    this.newNotifications = [...notifications];
  };
  setReadNotifications = (notifications: any[]) => {
    this.readNotifications = [...notifications];
  };
  setNotificationsTotal = (notificationsTotal: number) => {
    this.notificationsTotal = notificationsTotal;
  };

  getNewNotifications = async () => {
    const res = await notificationsList({
      'q[is_read_eq]': false,
      page_size: 10,
    });
    if (res) {
      this.setNewNotifications(res.items);
      this.setNotificationsTotal(res.paging.count);
      if (res.items.length) {
        this.setIdLtNew(res.items[res.items.length - 1].id);
      }
    }
  };

  getNotifications = async () => {
    this.getNewNotifications();
    const res = await notificationsList({
      'q[is_read_eq]': true,
      page_size: 10,
    });
    this.setReadNotifications(res?.items ?? []);
    if (res?.items?.length) {
      this.setIdLtRead(res.items[res.items.length - 1].id);
    }
  };

  newNotificationsList = async () => {
    this.setIconLoading(true);
    const data: any = { 'q[is_read_eq]': false, page_size: 10 };
    if (this.idLtNew) {
      data['q[id_lt]'] = this.idLtNew;
    }
    const res = await notificationsList(data);
    this.setNewNotifications(this.newNotifications.concat(res.items));
    if (res.items.length) {
      this.setIdLtNew(res.items[res.items.length - 1].id);
    }
    await sleep(500);
    this.setIconLoading(false);
  };

  readNotificationsList = async () => {
    this.setIconLoading(true);
    const data: any = { 'q[is_read_eq]': true, page_size: 10 };
    if (this.idLtRead) {
      data['q[id_lt]'] = this.idLtRead;
    }
    const res = await notificationsList(data);

    this.setReadNotifications(this.readNotifications.concat(res.items));
    if (res.items.length) {
      this.setIdLtRead(res.items[res.items.length - 1].id);
    }

    await sleep(500);
    this.setIconLoading(false);
  };

  markRead = async (id: number) => {
    await notificationsRead({ ids: [id] });
    this.getNotifications();
  };

  markReadAll = async () => {
    await notificationsReadAll();
    this.getNotifications();
  };
}
