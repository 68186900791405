import { useEffect } from 'react';
var BULLETLAYERID = 'code_ui-bullet-layer';
export var useLockScreen = function useLockScreen() {
  var skip = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  useEffect(function () {
    if (skip) return;
    if (document.querySelectorAll(".".concat(BULLETLAYERID)).length > 1) return;
    var styles = document.body.style.cssText;
    var top = document.documentElement.scrollTop || document.body.scrollTop;
    document.body.style.cssText = "".concat(styles, "\n                position: fixed;\n                width: 100vw;\n                left: 0;\n                top: ").concat(-top, "px;\n            ");
    return function () {
      if (document.querySelectorAll(".".concat(BULLETLAYERID)).length > 0) return;
      var top = document.body.style.top;
      document.body.style.cssText = "".concat(styles, " position: static;");
      window.scrollTo(0, Math.abs(parseFloat(top)));
    };
  }, []);
};
useLockScreen.BULLETLAYERID = BULLETLAYERID;