import dayjs from 'dayjs';

export function formatTimeToDaysBefore(timestamp: number) {
  const mistiming = Math.round((Date.now() - timestamp) / 1000);
  const arrDesc = ['年', '个月', '周', '天', '小时', '分钟', '秒'];
  const arrTimestamp = [31536000, 2592000, 604800, 86400, 3600, 60, 1];
  for (let i = 0; i < arrTimestamp.length; i++) {
    const inm = Math.floor(mistiming / arrTimestamp[i]);
    if (inm != 0) {
      return inm + arrDesc[i] + '前';
    }
  }
}

export function formatTimeToStandard(time?: string) {
  return dayjs(time).format('YYYY-MM-DD HH:mm:ss');
}
export function formatTimeToStandardNoSecond(time?: string) {
  return dayjs(time).format('YYYY-MM-DD HH:mm');
}
export function formatTimeToSlant(time: string) {
  return dayjs(time).format('YYYY-MM-DD');
}

export function formatTimeToMonthDay(time: string) {
  return dayjs(time).format('MM-DD');
}

export function getDateDiff(dateTimeStamp: string) {
  // 时间字符串转时间戳
  var timestamp = dayjs(dateTimeStamp).toDate().getTime();
  var minute = 1000 * 60;
  var hour = minute * 60;
  var day = hour * 24;
  var month = day * 30;
  var year = day * 365;
  var now = new Date().getTime();
  var diffValue = now - timestamp;
  var result;
  if (diffValue < 0) {
    return '';
  }
  var yearC = diffValue / year;
  var monthC = diffValue / month;
  var weekC = diffValue / (7 * day);
  var dayC = diffValue / day;
  var hourC = diffValue / hour;
  var minC = diffValue / minute;
  if (yearC >= 1) {
    result = '' + parseInt(String(yearC)) + '年前';
  } else if (monthC >= 1) {
    result = '' + parseInt(String(monthC)) + '月前';
  } else if (weekC >= 1) {
    result = '' + parseInt(String(weekC)) + '周前';
  } else if (dayC >= 1) {
    result = '' + parseInt(String(dayC)) + '天前';
  } else if (hourC >= 1) {
    result = '' + parseInt(String(hourC)) + '小时前';
  } else if (minC >= 1) {
    result = '' + parseInt(String(minC)) + '分钟前';
  } else result = '刚刚';
  return result;
}
