import request from '@code/request';
import type { AnimationConfigWithPath, AnimationItem } from 'lottie-web';

export type { AnimationConfigWithPath, AnimationItem };
export async function loadAnimation({
  path,
  ...params
}: AnimationConfigWithPath) {
  const ret = await request.get(`${window.location.origin}${path}`);
  const { default: lottie } = await import(
    /* webpackChunkName: "lottie" */
    'lottie-web'
  );
  return lottie.loadAnimation({
    ...params,
    renderer: 'svg',
    animationData: ret.data,
  });
}
