import { EXEC_TIMEOUT, NotAccessRoutes, PAAS_EXEC_TIMEOUT } from '@/constant';
import { DeviceInfo, useContext, useStore } from '@/store';
import { ResultPage } from '@/ui';
import { initEnv } from '@code/constant';
import { useMount } from '@code/hooks';
import { Button } from '@code/ui';
import { errorToJSON, log, Token } from '@code/utils';
import * as Sentry from '@sentry/nextjs';
import { ErrorBoundary, FallbackRender } from '@sentry/nextjs';
import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
  PreviewData,
} from 'next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import { FunctionComponent, useEffect, useMemo } from 'react';

const isShutdown = false;

interface SSRProps {
  referer: string;
  error?: { status: number; [k: string]: any };
}

export type SSRPageProps = SSRProps & { deviceInfo: DeviceInfo };

export const PM_WXNUMBER = 'CodingWith1024Code';

export const PM_WXURL = 'https://u.wechat.com/MBtcwKCNg-YkwAnwuHM5M7U';
export const PM_WXICON = '/avatar/2022062838-WXOjWgBRkJMMdkQc.png';

type GetSSRProps = (
  context: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
  params: SSRProps,
) => Promise<
  GetServerSidePropsResult<Record<string, any> & { routerName: string }>
>;

const fallbackRender: FallbackRender = ({ error, resetError }) => (
  <ResultPage
    status="500"
    description={
      <>
        <div>你遇到了一个错误</div>
        <div>{error.toString()}</div>
      </>
    }
    extra={
      <Button block type="primary" onClick={() => resetError()}>
        点击重试
      </Button>
    }
  />
);

export function withServerPage<T extends Record<string, any>>(
  getServerSideProps: GetSSRProps,
  Component: FunctionComponent<T & SSRPageProps>,
) {
  function withSSR(fn: GetSSRProps): GetServerSideProps {
    return async (context) => {
      const props: SSRProps = {
        referer: context.req.headers.referer || '',
      };

      if (context.resolvedUrl !== '/shutdown') {
        if (isShutdown) {
          return {
            redirect: {
              destination: '/shutdown',
              permanent: false,
            },
          };
        }
      } else if (!isShutdown) {
        return {
          redirect: {
            destination: '/',
            permanent: false,
          },
        };
      }

      try {
        const ret: any = await fn(context, props);
        if (ret.props) ret.props = { ...ret.props, ...props };
        return ret;
      } catch (err: any) {
        const error: SSRPageProps['error'] = errorToJSON(err);
        console.error(err);

        if (error?.status === 401) {
          return {
            redirect: {
              destination: '/logout',
              permanent: false,
            },
          };
        }
        return {
          props: { ...props, error },
        };
      }
    };
  }
  function Page(props: T & SSRPageProps & { routerName: string }) {
    const router = useRouter();
    const { routerStore } = useStore();
    const { isSignIn } = useContext();
    const pathname = useMemo(() => router.pathname, []);
    useMount(() => {
      log(window.location.pathname, props, router);

      if (Token.token && NotAccessRoutes.includes(router.route)) {
        router.replace('/');
        return;
      }
    });
    useEffect(() => {
      if (props.routerName && router.pathname === pathname) {
        routerStore.set(router.asPath, props.routerName);
      }
    }, [router.asPath]);

    if (props.error) {
      if (props.error.status == 404) {
        return (
          <>
            <Head>
              <title>404 - 1024Code</title>
            </Head>
            <ResultPage description="抱歉，你要找的资源不存在" />
          </>
        );
      }
      return (
        <>
          <Head>
            <title>500 - 1024Code</title>
          </Head>
          <ResultPage status="500" />
        </>
      );
    }

    return (
      <>
        <Head>
          {props.routerName ? (
            <title>{`${props.routerName} - 1024Code`}</title>
          ) : (
            <title>1024Code - 让编程重回乐趣</title>
          )}
        </Head>
        <ErrorBoundary fallback={fallbackRender}>
          <Component {...props} isSignIn={isSignIn} />
        </ErrorBoundary>
      </>
    );
  }
  return { Page, getServerSideProps: withSSR(getServerSideProps) };
}

export function basename(fileName: string) {
  var idx = fileName.lastIndexOf('/');
  idx = idx > -1 ? idx : fileName.lastIndexOf('\\');
  if (idx < 0) {
    return fileName;
  }
  return fileName.substring(idx + 1);
}

export const splitSlug = function (slug: string) {
  const [newSlug, text] = slug.split(' ');
  if (text) {
    return newSlug?.trim();
  }
  return '';
};

export const reorder = function <T>(
  list: T[],
  startIndex: number,
  endIndex: number,
) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const isDev = ['develop', 'staging', 'preview'].includes(
  process.env.NEXT_PUBLIC_CODE_ENV || 'develop',
);

export const logError = (
  message: any,
  needReport = true,
  ...optionalParams: any[]
) => {
  if (needReport) Sentry.captureException(message);
  console.error(message, ...optionalParams);
};

export const initEnvVar = (
  host: string | undefined,
  _search: Record<string, any>,
  _cookies: Record<string, string>,
) => {
  if (host === 'paas.staging.apps.1024code.com') {
    initEnv({
      PAAS_DOMAIN: 'https://develop.1024paas.com/',
      RAILS_HOST: 'paas.staging.apps.1024code.com',
    });
  }
};

function _exec_timeout<T, P extends Array<any>>(
  errMsg: string,
  timeout: number,
  function_call: (...agrs: P) => Promise<T>,
) {
  return (...agrs: P) =>
    new Promise<T>((resolve, reject) => {
      let timer: number | null = window.setTimeout(() => {
        if (timer) {
          window.clearTimeout(timer);
          timer = null;
          let params = '';
          try {
            if (agrs.length > 0) {
              params = JSON.stringify(agrs);
            }
          } catch (error) {}
          console.error(params ? `[${errMsg}]${params}` : errMsg);
          const e = new Error(errMsg);
          e.name = 'TIMEOUT';

          reject(e);
        }
      }, timeout);

      function_call(...agrs)
        .then((ret) => {
          if (timer) {
            resolve(ret);
            window.clearTimeout(timer);
            timer = null;
          }
        })
        .catch((err: Error) => {
          if (timer) {
            window.clearTimeout(timer);
            timer = null;

            reject(err);
          }
        });
    });
}

export function exec_timeout<T, P extends Array<any>>(
  errMsg: string,
  function_call: (...agrs: P) => Promise<T>,
) {
  return _exec_timeout(errMsg, EXEC_TIMEOUT, function_call);
}

export function paas_exec_timeout<T, P extends Array<any>>(
  errMsg: string,
  function_call: (...agrs: P) => Promise<T>,
) {
  return _exec_timeout(errMsg, PAAS_EXEC_TIMEOUT, function_call);
}
