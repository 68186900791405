export var storage = {
  setItem: function setItem(key, value) {
    if (typeof window === 'undefined') return;
    if (window.self !== window.top) return;
    if (!window.navigator.cookieEnabled) return;
    return window.localStorage.setItem(key, value);
  },
  getItem: function getItem(key) {
    if (typeof window === 'undefined') return null;
    if (window.self !== window.top) return null;
    if (!window.navigator.cookieEnabled) return null;
    return window.localStorage.getItem(key);
  },
  removeItem: function removeItem(key) {
    if (typeof window === 'undefined') return;
    if (window.self !== window.top) return;
    if (!window.navigator.cookieEnabled) return;
    return window.localStorage.removeItem(key);
  }
};