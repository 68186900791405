import { useContext } from '@/store';
import { UserPanePopover } from '@/ui';
import { formatTimeToSlant } from '@/utils/timeFormat';
import { useLockScreen } from '@code/hooks';
import { LoadingOutlined } from '@code/icons';
import {
  Badge,
  BadgeProps,
  Button,
  Empty,
  Image,
  Link,
  Popover,
  Segmented,
} from '@code/ui';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Key, useEffect, useState } from 'react';
import { NotificationStore } from './notificationStore';

interface NotificationsProps {
  offset: BadgeProps['offset'];
  children?: React.ReactNode;
}

type NotificationListProps = {
  title?: string;
  lockScreen?: boolean;
  notification: NotificationStore;
  onClose?: () => void;
};

const NotificationList = observer<NotificationListProps>(
  ({ title, lockScreen, notification, onClose }) => {
    useLockScreen(!lockScreen);
    const router = useRouter();
    const [segmentedVal, setSegmentedVal] = useState<Key>('新消息');

    const pushRouter = (url: string, query: any = {}) => {
      router.push({ pathname: url, query: { ...query, spm: 'notice' } });
      onClose?.();
    };
    const renderNotification = (item: any) => {
      let onClick: (() => void) | undefined;
      let content = <div title={item.content}>{item.content}</div>;
      switch (item.content_type) {
        case 'team_apply':
        case 'team_role_change':
        case 'team_owner_change':
          if (item.target.slug)
            onClick = () => {
              pushRouter(`/team/${item.target.slug}`, { tab: 'member' });
            };
          break;
        case 'team_approve':
        case 'team_invite':
        case 'team_quit':
          if (item.target.slug)
            onClick = () => {
              pushRouter(`/team/${item.target.slug}`);
            };
          break;
        case 'follow':
          if (item.target.slug)
            onClick = () => {
              pushRouter(`/~${item.target.slug}`);
            };
          break;
        case 'subscribe':
          const collection_slug =
            item.target.collection_slug || item.target.collection_id;
          if (collection_slug)
            onClick = () => {
              pushRouter(`/collections/${collection_slug}`);
            };
          break;
        case 'comment_reply':
          content = <div dangerouslySetInnerHTML={{ __html: item.content }} />;
          if (item.target.slug)
            onClick = () => {
              // TODO 需要跳转到具体评论
              pushRouter(`/codecubes/${item.target.slug}`);
            };
          break;

        case 'codecube_likes':
        case 'codecube_comment':
        case 'comment_block':
        case 'codecube_kick':
          if (item.target.slug)
            onClick = () => {
              pushRouter(`/codecubes/${item.target.slug}`);
            };
          break;

        case 'codecube_join':
        case 'public_fail':
          if (item.target.slug)
            onClick = () => {
              pushRouter(`/ide/${item.target.slug}`);
            };
          break;
        case 'public_success':
          if (item.target.slug)
            onClick = () => {
              pushRouter(`/codecubes/${item.target.slug}`);
            };
          break;
      }

      return (
        <div
          className="mt-5 pl-2.5 pb-2.5 last:border-0 border-b border-solid border-divider"
          key={item.id}
        >
          <div className="flex justify-between">
            <div className="text-sm font-normal text-foreground-2">
              {formatTimeToSlant(item.created_at)}
            </div>
          </div>
          <div className="flex mt-2.5">
            {item.provider ? (
              <UserPanePopover isSignIn slug={item.provider.slug}>
                <Link
                  href={`/~${item.provider.slug}`}
                  disabled={!item.provider.slug}
                  className="w-6 h-6 flex-shrink-0 rounded-circle cursor-pointer overflow-hidden"
                  onClick={onClose}
                >
                  <Image
                    width={24}
                    height={24}
                    pure
                    circle
                    className="w-6 h-6 text-4"
                    src={item.provider.avatar}
                    alt="用户头像"
                  />
                </Link>
              </UserPanePopover>
            ) : (
              <Image
                width={24}
                height={24}
                pure
                circle
                className="w-6 h-6 flex-shrink-0 overflow-hidden"
                src="/avatar/logo-transparent.png"
                alt="1024Code"
              />
            )}

            <div
              className={classNames(
                'ml-2.5 text-foreground-1 overflow-hidden',
                !!onClick && 'cursor-pointer hover:text-foreground-1/90',
              )}
              onClick={() => {
                onClick?.();
                notification.markRead(item.id);
              }}
            >
              <div>{item.title}</div>
              {content}
            </div>
          </div>
        </div>
      );
    };
    return (
      <div
        className={classNames(
          useLockScreen.BULLETLAYERID,
          'w-full py-3 lg:w-360 relative flex flex-col overflow-hidden',
        )}
      >
        <div className="px-6 lg:px-3">
          {title && <div>{title}</div>}
          <Segmented
            block
            className="mt-3"
            options={['新消息', '已读消息']}
            value={segmentedVal}
            onChange={setSegmentedVal}
          />
        </div>

        {segmentedVal === '新消息' && (
          <>
            <div className="w-full flex-1 overflow-hidden">
              {notification.newNotifications.length ? (
                <div
                  className="w-full px-6 lg:px-3 overflow-auto h-full max-h-[360px]"
                  onScroll={(e: any) => {
                    const flag =
                      e.target.clientHeight + e.target.scrollTop >=
                      e.target.scrollHeight;

                    if (flag) {
                      notification.newNotificationsList();
                    }
                  }}
                >
                  {notification.newNotifications.map(renderNotification)}
                </div>
              ) : (
                <Empty className="py-6" description="暂无新消息" />
              )}
            </div>
            {!!notification.newNotifications.length && (
              <div className="w-full py-3 px-6 lg:px-3 flex justify-end">
                <Button type="link" onClick={notification.markReadAll}>
                  标记全部已读
                </Button>
              </div>
            )}
          </>
        )}
        {segmentedVal === '已读消息' && (
          <div className="w-full flex-1 overflow-hidden">
            {notification.readNotifications.length ? (
              <div
                className="w-full px-6 lg:px-3 overflow-auto h-full max-h-[360px]"
                onScroll={(e: any) => {
                  const flag =
                    e.target.clientHeight + e.target.scrollTop >=
                    e.target.scrollHeight;
                  if (flag) {
                    notification.readNotificationsList();
                  }
                }}
              >
                {notification.readNotifications.map(renderNotification)}
              </div>
            ) : (
              <Empty className="py-6" description="暂无消息" />
            )}
          </div>
        )}
        {notification.iconLoading && (
          <div className="w-full flex justify-center absolute text-5 text-accent-primary bottom-3">
            <LoadingOutlined />
          </div>
        )}
      </div>
    );
  },
);

const InlineNotifications = observer<NotificationsProps>(
  ({ offset, children }) => {
    const { event$, isMobile, dialogApi } = useContext();
    const [visible, setVisible] = useState(false);
    const [notification] = useState(() => new NotificationStore());

    useEffect(() => {
      notification.setVisible(visible);
    }, [visible]);

    event$.useSubscription(({ type }) => {
      if (type === 'receivedNotifications') {
        notification.receivedNotifications();
      }
    });

    const onClick = () => {
      // notification.markReadAll();
      const { destroy } = dialogApi.confirm({
        title: '消息通知',
        mobile: true,
        footer: null,
        content: (
          <NotificationList
            notification={notification}
            onClose={() => {
              setVisible(false);
              destroy();
            }}
          />
        ),
      });
    };

    if (isMobile) {
      return (
        <Badge
          offset={offset}
          count={notification.notificationsTotal}
          size="small"
          onClick={onClick}
        >
          {children}
        </Badge>
      );
    }

    return (
      <Popover
        trigger="click"
        autoAdjustOverflow
        destroyTooltipOnHide
        placement="bottomRight"
        content={
          <NotificationList
            lockScreen
            title="消息通知"
            notification={notification}
            onClose={() => setVisible(false)}
          />
        }
        open={visible}
        onOpenChange={setVisible}
      >
        <Badge
          offset={offset}
          count={notification.notificationsTotal}
          size="small"
        >
          {children}
        </Badge>
      </Popover>
    );
  },
);

export const Notifications = dynamic(
  () => Promise.resolve(InlineNotifications),
  { ssr: false },
);
