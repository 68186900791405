import { useInvitationCodeCount } from '@/api/code';
import { userLogout } from '@/api/user';
import { data } from '@/pages/site/data';
import { useContext } from '@/store';
import {
  CodeCodeClose,
  CodeCodeExpandDown,
  CodeCollectionOutlined,
  CodeCommunity,
  CodeComputerFill,
  CodeIntelligentFill,
  CodePersonal,
  CodePostgraduateOutlined,
  CodeQrCodeOutlined,
  CodeQuestionFill,
  CodeRecommendFill,
  CodeSetFill,
  CodeTeamSpace,
} from '@code/icons';
import { DropdownProps, Link, LinkProps, Popover } from '@code/ui';
import classNames from 'classnames';
import { Fragment, useMemo, useState } from 'react';

type MenuInfo = {
  name: string;
  desc?: string;
  separate?: boolean;
  icon?: React.ReactNode;
  href?: LinkProps['href'];
  target?: LinkProps['target'];
  children?: MenuInfo[];
};

export const SubMenu = ({
  name,
  children,
}: MenuInfo & { onClose?: () => void }) => {
  const [open, setOpen] = useState(false);
  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      placement="bottomLeft"
      content={
        <div className="w-[240px] p-1 rounded-2 flex flex-col items-start bg-background-float">
          {children?.map((menu) => {
            if (!menu.href) return null;
            return (
              <Fragment key={menu.name}>
                <Link
                  href={menu.href}
                  className="mb-2 last:mb-0 group w-full p-2 rounded-2 flex items-center hover:bg-white/5"
                  onClick={() => setOpen(false)}
                >
                  <div className="w-11 h-11 rounded-2 flex items-center justify-center text-6 text-foreground-1 bg-white/5 group-hover:text-accent-primary">
                    {menu.icon}
                  </div>
                  <div className="ml-3 flex-1 flex flex-col overflow-hidden">
                    <div className="mb-1 truncate font-semibold text-sm text-foreground-1 group-hover:text-accent-primary">
                      {menu.name}
                    </div>
                    <div className="truncate text-xs text-foreground-2">
                      {menu.desc}
                    </div>
                  </div>
                </Link>
                {menu.separate && (
                  <div className="my-2 w-full h-0.5 bg-white/5"></div>
                )}
              </Fragment>
            );
          })}
        </div>
      }
    >
      <div className="px-3 py-2 flex items-center text-foreground-1 rounded-2 mr-2 last:mr-0 cursor-pointer hover:bg-white/10">
        <div className="mr-1 text-sm font-semibolde">{name}</div>
        <div
          className={classNames('text-3 transition-all', open && 'rotate-180')}
        >
          <CodeCodeExpandDown />
        </div>
      </div>
    </Popover>
  );
};

export const useNavMenus = (hasSignIn = false) => {
  const menus = useMemo(() => {
    const menus: (MenuInfo | null)[] = [
      {
        name: '编程社区',
        icon: <CodeCommunity />,
        href: '/community',
      },
      hasSignIn
        ? null
        : {
            name: '使用场景',
            icon: <CodeComputerFill />,
            children: [
              {
                name: data.intelligent.name,
                desc: data.intelligent.desc.join('。'),
                icon: <CodeIntelligentFill />,
                href: '/site/intelligent',
              },
              {
                name: data.learn.name,
                desc: data.learn.desc.join('，'),
                icon: <CodePostgraduateOutlined />,
                href: '/site/learn',
              },
              {
                name: data.summarize.name,
                desc: data.summarize.desc.join('，'),
                icon: <CodePersonal />,
                href: '/site/summarize',
                separate: true,
              },
              {
                name: data.collection.name,
                desc: data.collection.desc.join('，'),
                icon: <CodeCollectionOutlined />,
                href: '/site/collection',
              },
            ],
          },
      // hasSignIn
      //   ? null
      //   : {
      //       name: '培育计划',
      //       icon: <CodeTeamSpace />,
      //       href: '/foster',
      //     },

      hasSignIn
        ? {
            name: '空间模版',
            icon: <CodeTeamSpace />,
            href: '/templates',
          }
        : null,
      {
        name: '帮助中心',
        icon: <CodeQuestionFill />,
        href: 'https://docs.1024code.com/',
        target: '_blank',
      },
    ];

    return menus.filter((m) => !!m) as MenuInfo[];
  }, [hasSignIn]);

  return [menus] as const;
};

const Code = () => {
  const [count] = useInvitationCodeCount();

  return (
    <>
      我的邀请码
      {count >= 0 && <span className="text-accent-primary">（{count}）</span>}
    </>
  );
};

export const usePersonalMenus = (isMobile = false) => {
  const { event$, userInfo } = useContext();

  const personalMenus = useMemo(() => {
    if (!userInfo?.slug) return [];
    const items: DropdownProps['items'] = [
      {
        text: '个人主页',
        icon: <CodePersonal />,
        href: `/~${userInfo?.slug}`,
      },
      {
        text: '我的合集',
        icon: <CodeCollectionOutlined />,
        href: `/~${userInfo?.slug}?tab=collections`,
      },
      {
        text: '最近点赞',
        icon: <CodeRecommendFill />,
        href: `/~${userInfo?.slug}?tab=likes`,
        separate: true,
      },
      {
        text: <Code />,
        icon: <CodeQrCodeOutlined />,
        href: '/invitation-code',
        separate: true,
      },
      //   {
      //     text: '收藏',
      //     icon: <CodeCollect />,
      //     href: '/settings/profile',
      //   },
      //   {
      //     text: '关注',
      //     icon: <CodeSubscriptionOutlined />,
      //     href: '/settings/profile',
      //   },
      {
        text: '设置',
        icon: <CodeSetFill />,
        href: '/settings/profile',
      },
      {
        text: '问题反馈',
        icon: <CodeQuestionFill />,
        href: 'https://wj.qq.com/s2/9820801/875b/',
        target: '_blank',
        separate: !isMobile,
      },
      {
        icon: <CodeCodeClose />,
        text: '退出登录',
        onClick: () => {
          event$.emit({ type: 'logout' });
          return userLogout();
        },
      },
    ];

    return items;
  }, [userInfo?.slug]);

  return [userInfo, personalMenus] as const;
};
