import { useUserGPTUsage } from '@/api/user';
import { ITERATION_POINTS, MVP_POINTS } from '@/constant';
import { useLlmApi } from '@/hooks';
import { CodeWarnOutlined } from '@code/icons';
import { Button, Dropdown, Image, Link, Popover } from '@code/ui';
import dynamic from 'next/dynamic';
import { useMemo } from 'react';
import { usePersonalMenus } from './comm';
import { Notifications } from './Notifications';

interface MenuInfo {
  name: string;
  href: string;
}

const CreateButton = dynamic(() => import('./CreateButton'));

export const RightContent = () => {
  const openLlmApi = useLlmApi();
  const [usage] = useUserGPTUsage();
  const [userInfo, personalMenus] = usePersonalMenus();
  const rightMenus = useMemo(() => {
    const rightMenus: MenuInfo[] = [
      {
        name: '个人主页',
        href: `/~${userInfo?.slug}`,
      },
      //   {
      //     name: '收藏',
      //     href: '/templates',
      //   },
    ];

    if (userInfo?.has_collection) {
      rightMenus.push({
        name: '合集',
        href: `/~${userInfo?.slug}?tab=collections`,
      });
    }
    return rightMenus;
  }, [userInfo?.slug, userInfo?.has_collection]);

  return (
    <div className="flex items-center">
      <CreateButton className="mr-4" />
      <Notifications offset={[-14, 6]}>
        <div className="px-3 py-2 rounded-2 mr-2 cursor-pointer text-sm text-foreground-2 hover:text-foreground-1 hover:bg-white/5">
          消息
        </div>
      </Notifications>
      {rightMenus.map(({ name, href }) => (
        <Link
          key={name}
          href={href}
          className="px-3 py-2 rounded-2 mr-2 cursor-pointer text-sm text-foreground-2 hover:text-foreground-1 hover:bg-white/5"
        >
          {name}
        </Link>
      ))}

      <Dropdown
        width={180}
        trigger="click"
        placement="bottomRight"
        items={personalMenus}
        title={userInfo?.name}
        extra={
          <>
            {!!usage && (
              <div className="px-2 py-3 w-full border-y border-solid border-accent-divider">
                <div className="flex items-center pb-2 text-xs font-semibold text-foreground-2">
                  账户积分余额
                  <Popover
                    placement="bottom"
                    content={
                      <div className="p-3 w-[320px] flex flex-col items-start bg-background-float">
                        <div>积分用于：</div>
                        <div>
                          1. 体验 AI 导师功能：每创建一个作品将消耗 {MVP_POINTS}{' '}
                          积分，迭代一次作品将消耗 {ITERATION_POINTS} 积分。
                        </div>
                        <div>
                          2. 开发或体验社区 AI 应用时请求 1024Code GPT API
                          接口：每次请求消耗10积分。
                        </div>
                        <div className="mt-2">积分获取方式：</div>
                        <div>每成功发布一个作品到社区，可获赠100积分。</div>
                        <span>
                          每成功分享一次邀请码，可获赠100积分。
                          <Link
                            className="text-accent-primary hover:text-accent-primary-hover"
                            href="/invitation-code"
                          >
                            （去分享）
                          </Link>
                        </span>
                      </div>
                    }
                  >
                    <div className="cursor-pointer text-sm ml-1">
                      <CodeWarnOutlined />
                    </div>
                  </Popover>
                </div>
                <div className="flex items-center">
                  <div className="pr-3 text-xs font-semibold text-foreground-1">
                    {usage.quota - usage.usage}
                  </div>
                  <Button type="link" size="small" onClick={openLlmApi}>
                    查看消耗记录
                  </Button>
                </div>
              </div>
            )}
          </>
        }
      >
        <div className="w-8 h-8 rounded-circle cursor-pointer bg-bg-xl">
          <Image
            width={32}
            height={32}
            pure
            circle
            className="w-8 h-8 text-5 bg-bg-xl"
            src={userInfo?.avatar || ''}
            alt="avatar"
          />
        </div>
      </Dropdown>
    </div>
  );
};
