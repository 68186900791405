export const data = {
  learn: {
    name: '模仿创新编程',
    separator: '，',
    desc: [
      '每个 Coder 的第一个程序都是从模仿开始，模仿创新是学习编程最有效的方法。在 1024Code 编程社区，我们无需额外安装和配置环境，直接模仿和修改代码，更快地理解、掌握新的编程技术和概念',
    ],
  },
  intelligent: {
    name: 'AI 自主编程新范式',
    separator: '，',
    desc: [
      '编程以实践为核心，最快找到编程的兴趣是学习编程最好的驱动力。在 1024Code AI 智能体的引导下，我们可以无需前置学习大量编程基础知识，立即开始实践，在实践练习中真正学会编程，快速找到并保持对编程的乐趣',
    ],
  },
  summarize: {
    name: '成就驱动编程',
    separator: '',
    desc: [
      '结合代码空间、合集、个人编程主页总结知识点、技术栈、学习和思考过程，自我沉淀的同时，更好地',
      '对外分享和展示，进而收获更多成就，反哺学习动力',
    ],
  },
  collection: {
    name: '交互式编程合集打造',
    separator: '，',
    desc: [
      '边学边练，每一个合集中的实例代码都有独立的环境，支持多文件框架级别项目',
      '充分利用 AI 助手，更高效的理解和掌握编程知识',
    ],
  },
};
