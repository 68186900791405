import { useMount } from '@code/hooks';
import { Token } from '@code/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { HTMLAttributes, useState } from 'react';

export const HomeWrapper = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) => {
  const router = useRouter();
  const [isBrowser, setBrowser] = useState(false);

  useMount(() => {
    if (Token.isSignIn) {
      router.replace('/~');
      return;
    }
    setBrowser(true);
  });
  return (
    <div
      id="page-wrapper"
      {...props}
      className={classNames(
        className,
        'w-full flex flex-wrap content-between font-normal',
        isBrowser ? 'opacity-100' : 'opacity-0',
      )}
    >
      {children}
    </div>
  );
};

export {
  CodecubeItem,
  CollectionItem,
  IDECollectionItem,
  LottieAnimation,
  PinSpacer,
  ShareQRCode,
  useLottie,
  Users,
} from './components';
export { cooperations } from './types';
export type { HomePageProps } from './types';
