import request from '@code/request';
import { useMemo } from 'react';
import useSWR from 'swr';

const invitationCodes = async (data: Request.PagingParams = {}) => {
  const page = data?.page || 1;
  const page_size = data?.page_size || 1000;
  const params: any = { ...data, page, page_size };

  const res = await request.get('/invitation_codes', { params });
  const list: User.Code[] = res.data.items;
  const { count, total_pages } = res.data.paging;

  return {
    list,
    page: page + 1,
    count,
    total_pages,
    total_count: res.data.total_count,
    reward_llm_api_quota: res.data.reward_llm_api_quota,
  } as Request.PagingResult<User.Code>;
};
export const useInvitationCodeCount = () => {
  const { data } = useSWR('/invitation_codes', () => invitationCodes());
  const count = useMemo(() => {
    if (!data) return -1;
    return data?.list.length || 0;
  }, [data?.list]);

  return [count] as const;
};

export const invitationCodesByUsed = async (
  data: Request.PagingParams = {},
) => {
  const page = data?.page || 1;
  const page_size = data?.page_size || 10;
  const params: any = { ...data, page, page_size };

  const res = await request.get('/invitation_codes/used', { params });
  const list: User.Code[] = res.data.items;
  const { count, total_pages } = res.data.paging;

  return {
    list,
    page: page + 1,
    count,
    total_pages,
  } as Request.PagingResult<User.Code>;
};

export const useInvitationCodes = () => {
  const { data, mutate } = useSWR('/invitation_codes', () => invitationCodes());

  const loading = !data;

  const { code, list } = useMemo(() => {
    if (!data) return { code: '', list: [] };
    const unUseCodeInx = data?.list.findIndex((item) => !item.is_use);
    const list = [...data.list];
    if (unUseCodeInx >= 0) {
      // list.splice(unUseCodeInx, 1);
      return { code: data.list[unUseCodeInx].code, list };
    }
    return { code: '', list };
  }, [data?.list]);

  return {
    loading,
    code,
    list,
    mutate,
    count: data?.count || 0,
    reward_llm_api_quota: data?.reward_llm_api_quota || 0,
    total_count: data?.total_count || 0,
  };
};
