import { authRoutes, siteRoutes } from '@/constant';
import { DeviceInfo, StoreProvider } from '@/store';
import { Main } from '@/ui';
import { initEnvVar } from '@/utils';
import { useMount } from '@code/hooks';
import { App, AppTheme } from '@code/ui';
import classNames from 'classnames';
import { parse } from 'cookie';
import { isString } from 'lodash-es';
import NextApp, { AppContext, AppProps, NextWebVitalsMetric } from 'next/app';
import localFont from 'next/font/local';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { SWRConfig } from 'swr';

import '@/styles/globals.scss';
import '@/styles/nprogress.scss';
import '@/styles/styles.scss';

const dingTalkJinBuTiFont = localFont({
  src: '../fonts/DingTalkSans.ttf',
  variable: '--font-ding',
});

type CustomAppProps = AppProps & {
  theme: AppTheme;
  deviceInfo: DeviceInfo;
};

function CustomApp({
  theme,
  Component,
  pageProps,
  deviceInfo,
}: CustomAppProps) {
  const router = useRouter();

  useMount(() => {
    document.body.classList.add(dingTalkJinBuTiFont.variable);
    window.requestAnimationFrame = (function () {
      return (
        window.requestAnimationFrame ||
        // @ts-ignore
        window.webkitRequestAnimationFrame ||
        // @ts-ignore
        window.mozRequestAnimationFrame ||
        // @ts-ignore
        window.oRequestAnimationFrame ||
        // @ts-ignore
        window.msRequestAnimationFrame ||
        function (callback) {
          return window.setTimeout(callback, 1000 / 60);
        }
      );
    })();
  });

  const render = () => {
    if (authRoutes.includes(router.route)) {
      return <Component {...pageProps} />;
    }
    switch (router.route) {
      case '/generate':
      case '/community/publish':
      case '/youthcamp-bytedance':
        return <Component {...pageProps} />;
      case '/shutdown':
        return <Component {...pageProps} deviceInfo={deviceInfo} />;
      case '/embed-ide/[userSlug]/[codecubeSlug]':
        return (
          <main className="relative w-full h-vh overflow-hidden">
            <Component {...pageProps} />
          </main>
        );
      default:
        return (
          <Main>
            <Component {...pageProps} />
          </Main>
        );
    }
  };

  return (
    <SWRConfig
      value={{
        onErrorRetry: (error, _, __, revalidate, { retryCount }) => {
          // 404 时不重试。
          if (error.status === 404) return;

          // 特定的 key 时不重试。
          // if (key === '/api/user') return

          // 最多重试 3 次。
          if (retryCount >= 3) return;

          // 0.8秒后重试。
          setTimeout(() => revalidate({ retryCount: retryCount }), 800);
        },
      }}
    >
      <App
        theme={theme}
        className={classNames(
          siteRoutes.includes(router.route) ? 'bg-black' : 'bg-background-3',
        )}
      >
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />
          <meta
            name="description"
            content="1024Code 是一个 AI-First 的云端开发环境（CDE），集成了 Agent 智能体和编程社区，无需安装环境，任何设备，开箱即用。在 1024Code，和 AI 智能体、其他开发者一起，探索 L5 自主编程新范式"
          />
          <meta
            name="keywords"
            content="AI-First, 云端开发环境, CDE, Cloud Development Environments, Agent, AI 智能体, 编程社区, 1024Code, IDE, 编程, AI, AIGC, 轻协同"
          />
          <title>1024Code | 支持 AI 编程的云端开发环境</title>
          <link rel="manifest" href="/manifest.json" />
          <link
            href="/icons/icon-16x16.png"
            rel="icon"
            type="image/png"
            sizes="16x16"
          />
          <link
            href="/icons/icon-32x32.png"
            rel="icon"
            type="image/png"
            sizes="32x32"
          />
          <link rel="apple-touch-icon" href="/icons/icon-32x32.png" />
        </Head>
        <StoreProvider deviceInfo={deviceInfo}>
          {render()}
          <Script
            src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}`}
            strategy="afterInteractive"
          />
          <Script id="google-analytics" strategy="afterInteractive">
            {`
window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID}');
            `}
          </Script>
        </StoreProvider>
      </App>
    </SWRConfig>
  );
}

CustomApp.getInitialProps = async (context: AppContext) => {
  const ctx = await NextApp.getInitialProps(context);

  const ua = context.ctx.req?.headers['user-agent']?.toLocaleLowerCase() || '';
  const isIOS = /iphone|ipad|ipod/.test(ua);
  const isAndroid = /android/.test(ua);
  const isMac = /macintosh|mac os x/i.test(ua);
  const isNoIOSAndroid = /blackberry|opera mini|iemobile|wpdesktop/.test(ua);
  const isMobile = isIOS || isAndroid || isNoIOSAndroid;
  const deviceInfo: DeviceInfo = {
    isMac,
    isIOS,
    isAndroid,
    isMobile,
    isNoIOSAndroid,
  };

  const {
    host,
    cookie,
    'x-invoke-query': query,
  } = context.ctx.req?.headers || {};
  const search = isString(query) ? JSON.parse(decodeURIComponent(query)) : {};
  const cookies = isString(cookie) ? parse(cookie) : {};
  initEnvVar(host, search, cookies);

  return {
    ...ctx,
    deviceInfo,
  };
};

export function reportWebVitals({
  id,
  name,
  label,
  value,
}: NextWebVitalsMetric) {
  if (window?.gtag)
    window.gtag('event', name, {
      event_category:
        label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
      value: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
      event_label: id, // id unique to current page load
      non_interaction: true, // avoids affecting bounce rate.
    });
}

CustomApp.displayName = 'code-app';

export default CustomApp;
