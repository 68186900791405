import { useContext } from '@/store';
import { PM_WXICON, PM_WXURL } from '@/utils';
import { Image, QRCode } from '@code/ui';
import { useRouter } from 'next/router';

export const Comminicate = () => {
  const router = useRouter();
  const { isSignIn, userInfo } = useContext();
  if (!isSignIn) return null;
  if (!userInfo?.come_from) return null;
  if (router.route !== '/~') return null;
  return (
    <div className="hidden lg:block fixed right-8 bottom-8 z-max cursor-pointer group hover:bottom-6 hover:right-1">
      <Image
        width={72}
        height={72}
        pure
        src="assets/comminicate.png"
        alt="和更多创作者 互动交流"
        className="w-18 h-18 group-hover:hidden"
      />
      <div className="w-[144px] p-3 rounded-4 hidden group-hover:flex flex-col text-xs text-foreground-2 bg-background-1 shadow-float">
        <QRCode
          value={PM_WXURL}
          bordered={false}
          bgColor="#fff"
          color="#000"
          size={120}
          icon={PM_WXICON}
          iconSize={24}
        />
        <div className="pt-2">添加小助手微信</div>
        <div>回复「进群」加入微信群交流</div>
      </div>
    </div>
  );
};
