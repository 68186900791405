export const extension = (fileName) =>
  /(?:\.([^.]+))?$/.exec(fileName)?.[1] ?? '';

// const gravatarHost = 'cdn.sep.cc';
const avatarHost = 'ui-avatars.com';

const loader = ({ src, width, quality = 75 }) => {
  // if (src.indexOf(gravatarHost) >= 0) return `${src}&s=${width}`;
  if (src.indexOf(avatarHost) >= 0) return `${src}&size=${width}`;

  const ext = extension(src);
  if (['gif', 'svg'].includes(ext)) return src;
  return `${src}?imageView2/format/webp/q/${quality}/2/w/${width}`;
};

export default loader;
