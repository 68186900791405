import { Link } from '@code/ui';
import classNames from 'classnames';

interface FooterProps {
  className?: string;
}
export const Footer = ({ className }: FooterProps) => {
  return (
    <div
      className={classNames(
        className,
        'w-full text-center text-xs text-foreground-3',
      )}
    >
      <span>Copyright © 2022-2024 1024Code </span>{' '}
      <Link
        href="https://beian.miit.gov.cn/"
        target="_blank"
        className="text-foreground-3 hover:text-foreground-2 cursor-pointer"
      >
        粤ICP备19030132号-9
      </Link>
    </div>
  );
};
