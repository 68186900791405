import { getUserInfoBySlug } from '@/api/user';
import { useFollowUser, useUnFollowUser } from '@/hooks';
import { useContext } from '@/store';
import { Statistic } from '@/ui';
import { CodeAdd, LoadingOutlined } from '@code/icons';
import { Button, Image, Link, Popover } from '@code/ui';
import useSWR, { useSWRConfig } from 'swr';

interface UserPaneProps {
  slug: string;
  name?: string;
  isSignIn: boolean;
  userInfo?: User.Info;
}

type UserPanePopoverProps = UserPaneProps & { children?: React.ReactNode };

const Pane = ({ slug, userInfo }: UserPaneProps) => {
  const { mutate } = useSWRConfig();
  const { data } = useSWR(`/profile/${slug}`, () => getUserInfoBySlug(slug));
  const [followReq, onFollow] = useFollowUser({
    slug,
    onSuccess() {
      mutate(`/profile/${slug}`);
      if (userInfo?.slug) mutate(`/profile/${userInfo.slug}`);
    },
  });
  const [unFollowReq, onUnFollow] = useUnFollowUser({
    slug,
    onSuccess() {
      mutate(`/profile/${slug}`);
      if (userInfo?.slug) mutate(`/profile/${userInfo.slug}`);
    },
  });

  if (!data)
    return (
      <div className={'w-240 h-56 p-6 flex flex-col'}>
        <div className="w-full flex flex-col items-center gap-2 text-4 text-accent-primary">
          <LoadingOutlined />
        </div>
      </div>
    );
  return (
    <div
      className="w-240 p-6 flex flex-col"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <div className="flex justify-between mb-4">
        <Link href={`/~${data.slug}`} className="w-12 h-12 rounded-circle">
          <Image
            width={24}
            height={24}
            pure
            circle
            className="w-12 h-12 text-8"
            src={data.avatar}
            alt="用户头像"
          />
        </Link>
        <div className="flex items-center">
          {userInfo?.slug !== data.slug &&
            (data.is_following ? (
              <Button
                className="group"
                loading={unFollowReq.loading}
                onClick={onUnFollow}
              >
                <div className="hidden group-hover:block">取消关注</div>
                <div className="block group-hover:hidden">已关注</div>
              </Button>
            ) : (
              <Button
                icon={<CodeAdd />}
                loading={followReq.loading}
                onClick={onFollow}
              >
                关注
              </Button>
            ))}
        </div>
      </div>
      <Link
        href={`/~${data.slug}`}
        className="text-sm font-semibold text-foreground-1 hover:text-foreground-1"
      >
        {data.name}
      </Link>
      {!!data.description && (
        <div className="mt-2 max-h-15 text-xs line-clamp-3 text-foreground-2">
          {data.description}
        </div>
      )}
      <div className="w-full flex mt-6">
        <div className="flex-1 flex items-center justify-start text-xs text-foreground-2">
          发布空间数
          <div className="ml-1 text-xs text-foreground-1">
            <Statistic value={data.publication_count} />
          </div>
        </div>
        <div className="flex-1 flex items-center justify-start text-xs text-foreground-2">
          公开空间数
          <div className="ml-1 text-xs text-foreground-1">
            <Statistic value={data.public_count} />
          </div>
        </div>
      </div>
    </div>
  );
};

const UserPaneBySignIn = (props: UserPaneProps) => {
  const { userInfo } = useContext();
  return <Pane {...props} userInfo={userInfo} />;
};

export const UserPane = ({ name, ...props }: UserPaneProps) =>
  props.slug ? (
    props.isSignIn ? (
      <UserPaneBySignIn {...props} />
    ) : (
      <Pane {...props} />
    )
  ) : (
    <div className="p-3">{name}</div>
  );

export const UserPanePopover = ({
  children,
  ...props
}: UserPanePopoverProps) => {
  const { isMobile } = useContext();

  if (isMobile) return <>{children}</>;

  return (
    <Popover
      trigger={['hover']}
      placement="bottom"
      content={<UserPane {...props} />}
    >
      {children}
    </Popover>
  );
};
