import { template } from 'lodash-es';
import { useCallback, useMemo } from 'react';

interface Props {
  templ?: string;
  value?: number;
}

export const Statistic = ({ templ, ...props }: Props) => {
  const compiled = useCallback(template(templ, {}), []);
  const value = useMemo(() => {
    let value = props.value || 0;
    let str = `${props.value || 0}`;
    if (value >= 1000) {
      str = `${(value / 1000).toFixed(1)}k`;
    }
    if (templ) {
      str = compiled({ value });
    }
    return str;
  }, [props.value, templ]);

  return <>{value}</>;
};
