import { log } from '@code/utils';
import { makeAutoObservable } from 'mobx';
import { parseUrl } from 'query-string';

export interface RouterInfo {
  href: string;
  label: string;
}

export class RouterStore {
  routers: RouterInfo[] = [];
  private paths = new Set<string>();
  private routerMap = new Map<string, string>();

  constructor() {
    makeAutoObservable(this);
    this.set('/', '工作台');
    this.set('/community', '编程社区');
  }

  #isSpm(path: string) {
    const spm = parseUrl(path).query.spm as string;
    return ['details_fork', 'notice'].includes(spm);
  }

  #updateRouters() {
    const routers = Array.from(this.paths)
      .map((href) => {
        let router: RouterInfo | null = null;
        const label = this.routerMap.get(href);
        if (label) {
          router = { href, label };
        }
        return router;
      })
      .filter((r) => !!r) as RouterInfo[];
    this.routers = routers;
    log(
      'routers',
      routers.map(({ label, href }) => `[${label}](${href})`),
    );
  }

  set = (key: string, label: string) => {
    this.routerMap.set(key, label);
    this.#updateRouters();
  };

  judge = (pathname: string, path: string) => {
    this.#updateRouters();
    const lastPath = Array.from(this.paths).pop();
    log('routers:judge', pathname, path, lastPath);
    if (lastPath && this.#isSpm(lastPath)) {
      this.paths.delete(lastPath);
    } else if (this.paths.has(path)) {
      if (lastPath && Array.from(this.paths).slice(-2)[0] === path) {
        this.paths.delete(lastPath);
      }
    }
    this.#updateRouters();
  };

  go = (path: string) => {
    const paths = Array.from(this.paths);
    const inx = paths.findIndex((p) => p === path);
    if (inx >= 0) {
      this.paths = new Set(paths.slice(0, inx + 1));
    }
    this.#updateRouters();
  };

  back = () => {
    const paths = Array.from(this.paths);
    paths.pop();
    this.paths = new Set(paths);
    this.#updateRouters();
  };

  /**
   * 路由入栈
   *
   * - TODO 需要考虑 `replace` 情况，目前固定 `replace` 的 `pathname` 都是根，所以暂不处理
   * - TODO 需要考虑 `useHashState` 情况，目前使用 `useHashState` 只有ide页面，暂时单独处理了
   */
  push = (pathname: string, path: string) => {
    if (
      ['/ide/[...slug]', '/[mousername]/[slug]'].includes(pathname) &&
      path.match(/#\//g) != null
    ) {
      return;
    }

    if (this.#isSpm(path)) {
      this.paths.clear();
      this.paths.add('/');
      this.paths.add(path);
      this.#updateRouters();
      return;
    }

    if (['/ide/[...slug]'].includes(pathname)) {
      this.paths.clear();
      this.paths.add('/');
      this.#updateRouters();
      return;
    }

    // TODO 目前没有针对使用了useUrlState的非root页面做处理，目前还没有该业务场景
    if (
      [
        '/',
        '/login',
        '/search',
        '/community',
        '/templates',
        '/[mousername]',
        '/settings/profile',
      ].includes(pathname)
    ) {
      this.paths.clear();
    }

    this.paths.add(path);
    this.#updateRouters();
  };
}
