import { useContext } from '@/store';
import { useSize } from '@code/hooks';
import {
  CodeCodNews,
  CodeListOutlined,
  CodeSearch,
  LogoOriginal,
  LogoPlate,
} from '@code/icons';
import { Input, Link } from '@code/ui';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { HTMLAttributes, useEffect, useMemo, useRef, useState } from 'react';
import { LoginButton } from '../LoginButton';
import { SubMenu, useNavMenus } from './comm';
import { Notifications } from './Notifications';
import { RightContent } from './RightContent';

interface HeaderProps {
  hasHeader?: boolean;
}

const CreateButton = dynamic(() => import('./CreateButton'));
const Sidebar = dynamic(() => import('./Sidebar'), { ssr: false });
const NavBar = ({
  className,
  children,
  ...props
}: HTMLAttributes<HTMLElement>) => {
  return (
    <div
      {...props}
      className={classNames(
        className,
        'sticky w-full h-15 z-20 right-0 top-0 border-b border-solid border-white/5 bg-black/5 filter-header',
      )}
    >
      <div className="w-[calc(100vw-48px)] h-full mx-auto flex items-center justify-between">
        {children}
      </div>
    </div>
  );
};

export const Header = ({ hasHeader }: HeaderProps) => {
  const router = useRouter();
  const leftRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLDivElement>(null);

  const leftSize = useSize(leftRef);
  const rightSize = useSize(rightRef);
  const { event$, isSignIn } = useContext();
  const [menus] = useNavMenus(isSignIn);
  const [open, setOpen] = useState(false);
  const [searchValue, updateValue] = useState<string>(router.query.q as string);

  const searchPadding = useMemo(() => {
    if (!leftSize?.width || !rightSize?.width) return 480;
    const width =
      leftSize.width > rightSize.width ? leftSize.width : rightSize.width;
    return (width + 24) * 2;
  }, [leftSize?.width, rightSize?.width]);

  useEffect(() => {
    updateValue(hasHeader ? (router.query.q as string) : '');
  }, [router.query.q, hasHeader]);

  if (!hasHeader) return null;

  return (
    <>
      <NavBar
        className="hidden lg:block"
        style={{ '--search-padding': `${searchPadding}px` } as any}
      >
        <div ref={leftRef} className="flex items-center">
          <Link
            href="/~"
            aria-label="首页"
            className="flex items-center py-4 pr-5 text-5 cursor-pointer"
          >
            {isSignIn ? <LogoPlate /> : <LogoOriginal width={88} height={20} />}
          </Link>
          <div className="hidden lg:flex items-center">
            {menus.map(({ name, icon, href, ...menu }) =>
              href ? (
                <Link
                  key={name}
                  href={href}
                  unavailable={href === router.pathname}
                  className={classNames(
                    'px-3 py-2 rounded-2 mr-2 last:mr-0 text-sm font-semibolde hover:bg-white/10',
                    href === router.pathname
                      ? 'text-accent-primary  hover:text-accent-primary'
                      : 'text-foreground-1 hover:text-foreground-1',
                  )}
                  {...menu}
                >
                  {name}
                </Link>
              ) : (
                <SubMenu key={name} name={name} icon={icon} {...menu} />
              ),
            )}
          </div>
        </div>

        {isSignIn && (
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[calc(100%-var(--search-padding))] flex h-15 items-center justify-center">
            <div className="mx-12 w-full max-w-360">
              <Input.Search
                className="w-full"
                placeholder="搜索社区/我的代码空间"
                value={searchValue}
                onChange={(e) => updateValue(e.target.value)}
                onSearch={(value) => {
                  if (router.route == '/search') {
                    event$.emit({ type: 'search', data: value });
                    return;
                  }
                  if (router.route == '/~') {
                    router.push(`/search?q=${value}&c=codecubes`);
                    return;
                  }
                  router.push(`/search?q=${value}`);
                }}
              />
            </div>
          </div>
        )}

        <div ref={rightRef}>
          {isSignIn ? (
            <RightContent />
          ) : (
            <LoginButton id="login-btn" type="primary">
              登录/注册
            </LoginButton>
          )}
        </div>
      </NavBar>
      <NavBar className="block lg:hidden">
        <div className="flex items-center">
          <div
            className="py-2 text-7 text-foreground-2"
            onClick={() => setOpen(true)}
          >
            <CodeListOutlined />
          </div>
        </div>
        {isSignIn ? (
          <div className="flex items-center">
            <Link
              href={router.route == '/~' ? '/search?c=codecubes' : '/search'}
              className="text-5 text-foreground-2 py-2 mr-6"
            >
              <CodeSearch />
            </Link>
            <Notifications offset={[-2, 8]}>
              <div className="text-5 text-foreground-2 py-2">
                <CodeCodNews />
              </div>
            </Notifications>
            <CreateButton className="ml-6" />
          </div>
        ) : (
          <LoginButton id="login-btn-m" type="primary">
            登录/注册
          </LoginButton>
        )}
      </NavBar>
      <Sidebar hasSignIn={isSignIn} open={open} onOpenChange={setOpen} />
    </>
  );
};
