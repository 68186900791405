function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { CodeCorrectFill, CodeErrorFill, CodeWarnFill, LoadingOutlined } from '@code/icons';
import { log } from '@code/utils';
import { message } from 'antd';
import { isString } from 'lodash-es';
import { Fragment, useMemo } from 'react';
import { jsx as ___EmotionJSX } from "@emotion/react";
var keys = ['info', 'success', 'warning', 'error', 'loading'];
var iconMap = {
  info: ___EmotionJSX(Fragment, null),
  loading: ___EmotionJSX(LoadingOutlined, null),
  success: ___EmotionJSX(CodeCorrectFill, null),
  error: ___EmotionJSX(CodeErrorFill, null),
  warning: ___EmotionJSX(CodeWarnFill, null)
};
export var useMessage = function useMessage() {
  var _message$useMessage = message.useMessage(),
    _message$useMessage2 = _slicedToArray(_message$useMessage, 2),
    api = _message$useMessage2[0],
    contextHolder = _message$useMessage2[1];
  var messageApi = useMemo(function () {
    var clone = {
      destroy: function destroy() {
        return api.destroy();
      }
    };
    keys.forEach(function (type) {
      clone[type] = function (content) {
        var duration = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
        var onClose = arguments.length > 2 ? arguments[2] : undefined;
        if (!isString(content)) {
          log("[".concat(type, "]\u672A\u77E5\u9519\u8BEF\uFF1A"), content);
          content = '未知错误';
        }
        return api.open({
          type: type,
          content: content,
          icon: iconMap[type],
          style: {
            marginTop: 44
          },
          duration: duration,
          onClose: onClose
        });
      };
    });
    return clone;
  }, []);
  return [messageApi, contextHolder];
};