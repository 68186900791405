function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import { Fragment, useState } from 'react';
import Button from "../Button";
import { jsx as ___EmotionJSX } from "@emotion/react";
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "v7d24q",
  styles: "display:flex;justify-content:flex-end;padding-left:1.5rem;padding-right:1.5rem;padding-top:0.75rem;padding-bottom:2.25rem"
} : {
  name: "z2hvm7-Footer",
  styles: "display:flex;justify-content:flex-end;padding-left:1.5rem;padding-right:1.5rem;padding-top:0.75rem;padding-bottom:2.25rem;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "1r5ehci",
  styles: "display:flex;width:100%;align-items:center;padding-left:1.5rem;padding-right:1.5rem;padding-top:0.75rem;padding-bottom:0.75rem"
} : {
  name: "g0i45o-Footer",
  styles: "display:flex;width:100%;align-items:center;padding-left:1.5rem;padding-right:1.5rem;padding-top:0.75rem;padding-bottom:0.75rem;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "9m2gga",
  styles: "width:50%;flex:1 1 0%;padding-right:0.375rem"
} : {
  name: "15nigku-Footer",
  styles: "width:50%;flex:1 1 0%;padding-right:0.375rem;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "t082rg",
  styles: "width:50%;flex:1 1 0%;padding-left:0.375rem"
} : {
  name: "l6fu1w-Footer",
  styles: "width:50%;flex:1 1 0%;padding-left:0.375rem;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "1lnklqj",
  styles: "display:flex;align-items:center;justify-content:space-between;padding-left:1.5rem;padding-right:1.5rem;padding-top:0.75rem;padding-bottom:1.5rem"
} : {
  name: "15179k6-Footer",
  styles: "display:flex;align-items:center;justify-content:space-between;padding-left:1.5rem;padding-right:1.5rem;padding-top:0.75rem;padding-bottom:1.5rem;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "s5xdrg",
  styles: "display:flex;align-items:center"
} : {
  name: "1sn1ru5-Footer",
  styles: "display:flex;align-items:center;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "1yg170s",
  styles: "height:0.25rem;width:0.75rem"
} : {
  name: "1ep67cj-Footer",
  styles: "height:0.25rem;width:0.75rem;label:Footer;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
export var Footer = function Footer(_ref) {
  var mobile = _ref.mobile,
    children = _ref.children,
    _ref$cancel = _ref.cancel,
    cancel = _ref$cancel === void 0 ? true : _ref$cancel,
    _ref$okClosable = _ref.okClosable,
    okClosable = _ref$okClosable === void 0 ? true : _ref$okClosable,
    _ref$okText = _ref.okText,
    okText = _ref$okText === void 0 ? '确定' : _ref$okText,
    _ref$cancelText = _ref.cancelText,
    cancelText = _ref$cancelText === void 0 ? '取消' : _ref$cancelText,
    okButtonProps = _ref.okButtonProps,
    cancelButtonProps = _ref.cancelButtonProps,
    onOk = _ref.onOk,
    onCancel = _ref.onCancel,
    onClose = _ref.onClose;
  var _useState = useState(false),
    _useState2 = _slicedToArray(_useState, 2),
    okLoading = _useState2[0],
    setLoading = _useState2[1];
  var handleOk = function handleOk() {
    var ret = onOk === null || onOk === void 0 ? void 0 : onOk();
    if (!okClosable) return;
    if (ret instanceof Promise) {
      setLoading(true);
      ret.then(function () {
        setLoading(false);
        onClose === null || onClose === void 0 ? void 0 : onClose();
      }).catch(function () {
        setLoading(false);
      });
    } else {
      onClose === null || onClose === void 0 ? void 0 : onClose();
    }
  };
  var handleCancel = function handleCancel() {
    var ret = onCancel === null || onCancel === void 0 ? void 0 : onCancel();
    if (ret instanceof Promise) {
      ret.then(function () {
        onClose === null || onClose === void 0 ? void 0 : onClose();
      });
    } else {
      onClose === null || onClose === void 0 ? void 0 : onClose();
    }
  };
  if (!cancel) {
    return ___EmotionJSX("div", {
      css: _ref2
    }, ___EmotionJSX(Button, _extends({
      type: "primary",
      size: "large",
      block: true,
      loading: okLoading
    }, okButtonProps, {
      onClick: handleOk
    }), okText));
  }
  if (mobile) {
    return ___EmotionJSX(Fragment, null, children, ___EmotionJSX("div", {
      css: _ref3
    }, ___EmotionJSX("div", {
      css: _ref4
    }, ___EmotionJSX(Button, _extends({
      block: true,
      type: "secondary",
      size: "large"
    }, cancelButtonProps, {
      onClick: handleCancel
    }), cancelText)), ___EmotionJSX("div", {
      css: _ref5
    }, ___EmotionJSX(Button, _extends({
      block: true,
      type: "primary",
      size: "large",
      loading: okLoading
    }, okButtonProps, {
      onClick: handleOk
    }), okText))));
  }
  return ___EmotionJSX("div", {
    css: _ref6
  }, ___EmotionJSX("div", null, children), ___EmotionJSX("div", {
    css: _ref7
  }, ___EmotionJSX(Button, _extends({
    type: "secondary"
  }, cancelButtonProps, {
    onClick: handleCancel
  }), cancelText), ___EmotionJSX("div", {
    css: _ref8
  }), ___EmotionJSX(Button, _extends({
    type: "primary",
    loading: okLoading
  }, okButtonProps, {
    onClick: handleOk
  }), okText)));
};