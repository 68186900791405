import { useContext } from '@/store';
import { formatTimeToStandard } from '@/utils/timeFormat';
import { useAntdTable } from '@code/hooks';
import request from '@code/request';
import { Button, Empty, Table } from '@code/ui';

export const useLlmApi = () => {
  const { dialogApi } = useContext();

  const open = () => {
    dialogApi.confirm({
      width: 640,
      footer: null,
      title: '积分消耗记录',
      content: ({ onClose }) => <LlmApiList onClose={onClose} />,
    });
  };

  return open;
};

const LlmApiList = ({}: { onClose?: () => void }) => {
  const { tableProps, loading } = useAntdTable(
    async ({ current, pageSize }) => {
      const res = await request.get<any>('/llm_api_usages', {
        params: { page: current, page_size: pageSize },
      });
      const list: any[] = res.data.items;
      const { count } = res.data.paging;
      return {
        total: count,
        list,
      };
    },
    { defaultPageSize: 6 },
  );
  return (
    <div className="w-full h-full p-6">
      <Table
        {...tableProps}
        loading={loading}
        pagination={{
          ...tableProps.pagination,
          size: 'small',
          hideOnSinglePage: true,
        }}
        locale={{
          emptyText: <Empty description="暂无积分消耗记录" className="py-6" />,
        }}
        columns={[
          {
            title: '消耗/充值事件',
            dataIndex: 'task_name',
            key: 'task_name',
            render: (task_name) => {
              switch (task_name) {
                case 'invite':
                  return '分享成功';
                case 'publication':
                  return '成功发布作品';
                case 'system_assign':
                  return '系统赠送';
                case 'native_api':
                  return '请求一次大模型 API 接口';
                case 'proteus:CreateCodecube':
                  return 'AI 生成应用';
                case 'proteus:StartIteration':
                  return '执行一次 Agent 迭代优化任务';

                default:
                  return '-';
              }
            },
          },
          {
            title: '消耗/充值积分',
            dataIndex: 'point',
            key: 'point',
            render: (point, record) => {
              if (record.usage_type === 'rewarded') {
                return `+${point}`;
              }
              return `-${point}`;
            },
          },
          {
            title: '剩余积分',
            dataIndex: 'remaining_points',
            key: 'remaining_points',
          },
          {
            title: '消耗代码空间',
            dataIndex: 'codecube_id',
            key: 'codecube_id',
            render: (_, record) => {
              if (record.codecube)
                return (
                  <Button
                    type="link"
                    target="_blank"
                    href={`/codecube/${record.codecube.slug}`}
                  >
                    {record.codecube.name}
                  </Button>
                );
              return '-';
            },
          },
          {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at) => formatTimeToStandard(created_at),
          },
        ]}
      />
    </div>
  );
};
