import { useContext } from '@/store';
import { logError } from '@/utils';
import request from '@code/request';
import { parseMarkdown, sleep, Token, Track } from '@code/utils';
import router from 'next/router';
import { useEffect } from 'react';
import useSWR from 'swr';

async function withSessions(res: any) {
  Track.identify(res.user_id, {
    phone: res.phone,
    name: res.name,
    email: res.email,
  });
  Token.set(res.token);
  await sleep(300);
}

// 用户登录
export async function userLogin(data: object) {
  const r = await request.post('/sessions', data);
  Track.capture('用户密码登录');
  await withSessions(r.data);
  return r.data;
}

// 用户登出
export async function userLogout() {
  try {
    const r = await request.delete('/sessions');
    return r.data;
  } finally {
    Token.remove();
    Track.logout();
    router.push('/login');
  }
}

// 用户注册
export async function userRegister(data: object) {
  const r = await request.post('/registrations/phone_reg', data);
  return r.data;
}

// 用户申请注册
export async function applyRegister(data: object) {
  const r = await request.post('/registrations/apply', data);
  return r.data;
}

// 获取用户信息
export async function getUserInfo(parse = false) {
  const r = await request.get('/profile');
  const userInfo = r.data as User.Info;
  if (userInfo) {
    if (userInfo.readme && parse)
      userInfo.readme = await parseMarkdown(userInfo.readme);
  }

  return userInfo;
}

export const getUserInfoBySSR = async (cookies: any) => {
  let userInfo: User.Info | null = null;
  const { [Token.TOKEN]: token } = cookies || {};
  try {
    if (token) {
      const r = await request.get('/profile', {
        headers: { authorization: `Token ${token}` },
      });
      if (r) userInfo = r.data as User.Info;
    }
  } catch (error) {
    logError(error);
  }

  return userInfo;
};

// 获取用户信息
export async function getUserInfoBySlug(slug: string, parse = true) {
  const r = await request.get(`/profile/${slug}`);
  const userInfo = r.data as User.Info;

  if (userInfo) {
    if (userInfo.readme && parse)
      userInfo.readme = await parseMarkdown(userInfo.readme);
  }

  return userInfo;
}

// 获取登录验证码
export async function captcha(data: { login_id: string }) {
  const r = await request.post('/sessions/captcha', data);
  return r.data;
}

// 验证码登录
export async function captchaLogin(data: object) {
  const r = await request.post('/sessions/captcha_login', data);
  Track.capture('用户验证码登录');
  await withSessions(r.data);
  return r.data;
}

// 注册验证码
export async function captchaRegister(data: { login_id: string }) {
  const r = await request.post('/registrations/captcha', data);
  return r.data;
}

// 注册认证
export async function validateRegistrations(params: object) {
  const r = await request.get('/registrations/validate', { params: params });
  return r.data;
}

// 上传文件，获取临时密钥
export async function getSecret(data: any) {
  const r = await request.post('/assets/upload_token', data);
  return r.data;
}

// 修改用户信息，头像名字简介等
export async function updateUserInformation(
  data: Omit<Partial<User.Info>, 'user_id'>,
) {
  const r = await request.put('/profile', data);
  return r.data;
}

export async function updateUserBySlug(slug: string) {
  const r = await request.put('/profile/update_slug', { slug });
  return r.data;
}

// 修改用户置顶代码空间
export async function setPinnedItems(data: {
  pinned_item_ids: Codecubes.Info[];
}) {
  const r = await request.post('/profile/set_pinned_items', {
    pinned_item_ids: data.pinned_item_ids.map((item) => item.id),
  });
  return r.data;
}

// 旧手机号验证码
export async function getOldPhoneCaptcha(data: any) {
  const r = await request.post('/profile/captcha_validate', data);
  return r.data;
}

/**
 * @description 通用接口
 * @description 验证旧手机号
 * @description 修改密码时验证手机号
 * @description 修改绑定邮箱时验证邮箱
 * */
export async function validateExistPhoneOrEmail(data: {
  login_id: string;
  captcha: string;
  send_type?: 'email';
}) {
  const r = await request.get('/profile/validate', { params: data });
  return r.data;
}

// 新手机号验证码
export async function getNewPhoneCaptcha(data: { login_id: string }) {
  const r = await request.post('/profile/captcha_change_phone', data);
  return r.data;
}

// 修改手机号
export async function changeUserPhone(data: {
  login_id: string;
  captcha: string;
}) {
  const r = await request.put('/profile/phone', data);
  return r.data;
}

// 修改密码
export async function changeUserPassword(data: {
  verify_by: 'password' | 'phone' | 'email';
  captcha: string;
  password: string;
  new_password: string;
  new_password_confirmation: string;
}) {
  const r = await request.put('/profile/password', data);
  return r.data;
}

// 修改密码验证密码
export async function validateExistPassword(data: { password: string }) {
  const r = await request.post('/profile/validate_password', data);
  return r.data;
}

// 获取所有用户
export async function userList(params: object) {
  const r = await request.get('/users', { params });
  return r.data;
}

// 忘记密码验证码
export async function forgetPasswordCaptcha(data: { login_id: string }) {
  const r = await request.post('/profile/captcha_validate', data);
  return r.data;
}

// 忘记密码重置密码
export async function forgetPasswordResetPassword(data: object) {
  const r = await request.put('/profile/password', data);
  return r.data;
}

// 邮箱号验证码
export async function getBindingEmailCaptcha(logins: { login_id: string }) {
  const data = {
    login_id: logins.login_id,
    send_type: 'email',
  };
  const r = await request.post('/profile/captcha_validate', data);
  return r.data;
}

// 验证绑定的邮箱
export async function bingingOrUpdateEmail(data: {
  login_id: string;
  captcha: string;
}) {
  const r = await request.put('/profile/email', data);
  return r.data;
}

export async function voiceCaptcha(data: {
  login_id: string;
  captcha_for: 'user_register' | 'user_login' | 'change_phone' | 'validate';
}) {
  const r = await request.post('/profile/voice_captcha', data);
  return r.data;
}

// 获取签名token
export async function anonymousToken(): Promise<{ token: string }> {
  const r = await request.post('/assets/anonymous_token');
  return r.data;
}

export async function getUserGPTUsage() {
  const r = await request.get<{ quota: number; usage: number }>(
    '/llm_api/usage',
  );
  return r.data;
}

export function useUserGPTUsage() {
  const { isSignIn } = useContext();
  const { data, mutate } = useSWR('/llm_api/usage', async () => {
    if (!isSignIn) return false;
    return getUserGPTUsage();
  });
  useEffect(() => {
    mutate();
  }, [isSignIn]);
  const loading = !data;
  return [data!, loading] as const;
}

export async function likeCodecubes(data: Request.PagingParams = {}) {
  const page = data?.page || 1;
  const page_size = data?.page_size || 6;
  const params: any = { ...data, page, page_size };
  if (page === 1) {
    delete params.seed;
  }

  const res = await request.get('/like_codecubes', { params });
  let list: Codecubes.Info[] = res.data.items;
  const { count, total_pages } = res.data.paging;

  return {
    list,
    page: page + 1,
    count,
    total_pages,
    seed: res.data?.seed,
  } as Request.PagingResult<Codecubes.Info>;
}
