import request from '@code/request';

// 消息通知列表
export async function notificationsList(params: object) {
  const r = await request.get('/notifications', { params });
  return r.data;
}

// 消息通知标为已读
export async function notificationsRead(data: object) {
  const r = await request.put('/notifications/read', data);
  return r.data;
}

// 消息通知标为全部已读
export async function notificationsReadAll() {
  const r = await request.put('/notifications/read_all');
  return r.data;
}
